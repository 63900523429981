import React from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Audio from "../containers/Audio"
import { muteAudio, unmuteAudio } from "../js/audioManager"

// TODO: Hiding AUDIO button just for Soft Live

var isMuted = false

const Footer = props => (
  <footer
    className={
      "footer " +
      `${props.color + "-gradiant"}` +
      " " +
      `${props.isTrainActive ? "hide" : ""}`
    }
  >
    <ul className="social-icons">
      <li>
        <a
          href="https://www.facebook.com/PUMA"
          rel="noopener noreferrer"
          target="_blank"
        >
          Facebook
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/puma"
          rel="noopener noreferrer"
          target="_blank"
        >
          Instagram
        </a>
      </li>

      <li>
        <a id="mute-audio" onClick={ () => { toggleAudio() }}>Audio
          <img src={require("./../images/AUDIO ICON.png")} alt="AUDIO ICON"/>
        </a>
      </li>

      {/*<li>*/}
        {/*<a>Audio*/}
          {/*<img src={require("./../images/AUDIO ICON.png")} alt="AUDIO ICON"/>*/}
        {/*</a>*/}
      {/*</li>*/}
    </ul>
  </footer>
)

const toggleAudio = () => {
  var muteButton = document.getElementById("mute-audio")
  if (isMuted) {
    muteButton.classList.remove("muted")
    unmuteAudio()
    isMuted = false
  } else {
    muteButton.classList.add("muted")
    muteAudio()
    isMuted = true
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive,
    isPlaying: state.isMuted
  }
}

export default connect(mapStateToProps)(Footer)
