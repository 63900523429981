import { poseData, warmUpData } from "../dataset/data.js"
import * as posenet from "@tensorflow-models/posenet"
import { l2norm } from "./libraries/l2norm/index.js"
const VPTreeFactory = require("vptree")
var vptree
var warmUpTree
var _ = require("lodash")

async function InitPosenetData() {
  console.log("poseData init: ", poseData)
  vptree = await buildVPTree(poseData)
}

async function InitWarmUpData() {
  console.log("warmUpData init: ", warmUpData)
  warmUpTree = await buildVPTree(warmUpData)
}

// poseVector1 and poseVector2 are 52-float vectors composed of:
// Values 0-33: are x,y coordinates for 17 body parts in alphabetical order
// Values 34-51: are confidence values for each of the 17 body parts in alphabetical order
// Value 51: A sum of all the confidence values
// Again the lower the number, the closer the distance
function weightedDistanceMatching(poseVector1, poseVector2) {
  const partsEnd = parts.length * 2
  const scoresEnd = partsEnd + parts.length
  let vector1PoseXY = poseVector1.slice(0, partsEnd)
  let vector1Confidences = poseVector1.slice(partsEnd, scoresEnd)
  let vector1ConfidenceSum = poseVector1.slice(scoresEnd, scoresEnd + 1)

  let vector2PoseXY = poseVector2.slice(0, partsEnd)

  // First summation
  let summation1 = 1 / vector1ConfidenceSum

  // Second summation
  let summation2 = 0
  for (let i = 0; i < vector1PoseXY.length; i++) {
    let tempConf = Math.floor(i / 2)
    let tempSum =
      vector1Confidences[tempConf] *
      Math.abs(vector1PoseXY[i] - vector2PoseXY[i])
    summation2 = summation2 + tempSum
  }

  return summation1 * summation2
}

async function buildVPTree(poseData) {
  // Initialize our vptree with our images’ pose data and a distance function
  return new Promise(resolve => {
    resolve(VPTreeFactory.build(poseData, weightedDistanceMatching))
  })
}

function findMostSimilarMatch(vptree, userPose) {
  const pose = convertPoseToVector(userPose)
  // search the vp tree for the image pose that is nearest (in cosine distance) to userPose
  let nearestImage = vptree.search(pose, 5)
  // return index (in relation to poseData) of nearest match.
  return {
    matches: nearestImage
  }
}

const parts = [
  "nose",
  //'leftEye',
  //'rightEye',
  //'leftEar',
  //'rightEar',

  "leftShoulder",
  "rightShoulder",

  "leftElbow",
  "rightElbow",
  "leftWrist",
  "rightWrist",

  "leftHip",
  "rightHip",
  "leftKnee",
  "rightKnee",
  "leftAnkle",
  "rightAnkle"
]

function convertPoseToVector(pose) {
  const keypoints = _.sortBy(normalizeKeypoints(pose), "part")
  const vector = keypoints.reduce((acc, keypoint) => {
    if (parts.includes(keypoint.part)) {
      acc.push(keypoint.normalizedPosition.x)
      acc.push(keypoint.normalizedPosition.y)
    }
    return acc
  }, [])

  const scoreSum = keypoints.reduce((acc, keypoint) => {
    vector.push(keypoint.score)
    return acc + keypoint.score
  }, 0)

  vector.push(scoreSum)
  return l2normPoseVector(vector)
}

function normalizeKeypoints(pose) {
  const boundingBox = posenet.getBoundingBox(pose.keypoints)

  const normalizedPoints = pose.keypoints.map(keypoint => {
    return {
      ...keypoint,
      normalizedPosition: {
        x: keypoint.position.x - boundingBox.minX,
        y: keypoint.position.y - boundingBox.minY
      }
    }
  })
  return normalizedPoints
}

function l2normPoseVector(vector) {
  const norm = l2norm(vector)
  const normalized = vector.map(value => (value / norm) * (value / norm))
  // console.log(normalized.reduce((acc, value) => acc + value, 0))
  return normalized
}

export {
  InitPosenetData,
  InitWarmUpData,
  findMostSimilarMatch,
  vptree,
  warmUpTree
}
