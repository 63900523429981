import React, { Component } from "react"
import { SceneCanvas } from "./../components" // import the component above linking to file we just created.
import { renderScene } from "./../js/sceneManager"

export default class Scene extends Component {
  render() {
    return (
      <div id="scene-container">
        <SceneCanvas onSceneMount={renderScene} />
      </div>
    )
  }
}
