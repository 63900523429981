import React, {Component} from "react"
import axios from "axios"
import {connect} from "react-redux"
import AWS from "aws-sdk"
import {BUCKET_NAME,POOL_ID,REGION,FEMALE_SHOE_URL,MALE_SHOE_URL} from "../utils/const";

export default class EmailPopUp extends Component {

  state = {
    emailSend: false,
    emailError: false,
    loadingEmail: false,
    email: "",
    isError: false,
  };

  onRetry = () => {
    this.setState({
      emailSend: false,
      emailError: false
    })
  }

  handelInput = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  upload = (gif) => {
    const {dataURIToBlob} = this, self = this
    const {email} = this.state
    let {shoeType} = this.props


    let s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: BUCKET_NAME }
    })
    const base64Data = dataURIToBlob(gif)

    s3.upload({
      Key: "image" + Date.now() + ".gif",
      Body: base64Data,
      ACL: "public-read",
      ContentType: "image/gif"
    }, function (err, data) {
      if (err) {
        return console.error("There was an error uploading your photo: ", err.message)
      } else {
        let _data = new FormData();


        _data.append("api_key", "pk_b2b8eb3006c198df7ce8792264d30a5326")
        _data.append("from_email", "onlinestore-au@puma.com")
        _data.append("from_name", "ASTRO BY PUMA")
        _data.append("subject", "Thanks for training Astro.")
        _data.append("to", email)
        _data.append("context", JSON.stringify({imgdata: data.Location}))

        let url = shoeType === "female" ? FEMALE_SHOE_URL : MALE_SHOE_URL

        self.setState({emailSend: true, loadingEmail: false})
        axios.post(url, _data).then(res => {})
      }
    })
  }

  dataURIToBlob = (dataURI) => {
    let binary = atob(dataURI.split(",")[1]);
    let array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    return new Blob([new Uint8Array(array)], {
      type: mimeString
    });
  }

  onSendEmail = (e) => {
    e.preventDefault();
    const {gifs, activeGif} = this.props

    this.setState({
      loadingEmail: true,
    }, () => {
      // Validate the user's email
      if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)){
        AWS.config.update({
          region: REGION,
          credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: POOL_ID
          })
        });

        Object.entries(gifs).map((gif) => {
          if (gif[0] === activeGif.toLowerCase()) {
            this.upload(gif[1])
          }
        })
      } else {
        console.error('Invalid email address');
      }
    });
  };

  render() {
    const { handelPopUp } = this.props;
    const { email, emailSend, loadingEmail } = this.state;
    const { onSendEmail, handelInput } = this;
    const background = this.props.color.split("-")[1] + "-bg";
    const reverse = (background === 'black-bg') ? '-reverse' : '';

    return (
      <div className="pop-up email-pop">
        <div className={"cam-access " + `${background}`}>
          <figure onClick={handelPopUp} className="close">
            <img src={require("./../images/cross" + reverse + ".png")} />
          </figure>
          {loadingEmail && (
              <>
                <p className="email-pop-message"><span>Please enter your email address so <span className="nowrap">ASTRO BY PUMA</span></span> <span>can send the exercise to you for sharing.</span></p>
                <p className="email-pop-loading">Loading</p>
              </>
          )}
          {emailSend && (
            <div className="thanks-message">
              <h3>Thank you for sharing. </h3>
              <p>
                <span>You will receive a email shortly from</span> <span>ASTRO BY PUMA with your shareable GIF.</span>
              </p>
            </div>
          )}
          {!emailSend && !loadingEmail && (
              <>
                <p className="email-pop-message"><span>Please enter your email address so <span className="nowrap">ASTRO BY PUMA</span></span> <span>can send the exercise to you for sharing.</span></p>
                <form action="" onSubmit={onSendEmail}>
                  <div className="form-item">
                    <input value={email} className="inputs" type="email" onChange={handelInput} placeholder="Enter Your Email ..."/>
                  </div>
                  <div className="form-item">
                    <input type="submit" className="astro-btn" value="Send"/>
                  </div>
                </form>
              </>
          )}
        </div>
      </div>
    )
  }
}
