import React, { Component } from "react"
import { placeAstro, playDemonstration } from "./../js/sceneManager"
import { CalculateCategoryLevel } from "../utils/helper"
import {connect} from "react-redux";


class EvolutionLevel extends Component {
    state = {
        activeDemo : null,
    }

    sanitizeName (string) {
        return string.toLowerCase().replace(/[\W_]+/g, '-');
    }

    render () {
        const { activeDemo } = this.state
        const {categories, handelSublevel, toggleMobileLevel, mobileSublevel, name, subLevelShow, background} = this.props
        const reverse = (background === 'home-black') ? '-reverse' : ''
        const levelPercentage = CalculateCategoryLevel(categories[name]);

        console.log(this.props);

        let activeDemoTimeout;

        function toggleSection() {
            if(mobileSublevel){
                toggleMobileLevel();
            } else {
                handelSublevel(name)
            }
        }

        function currentLevelHeading () {
            let output = '';

            switch(true){
                case levelPercentage >= 36:
                    output = <>LEVEL TWO: <span>MEDIUM</span></>;
                    break;
                case levelPercentage >= 66:
                    output = <>LEVEL THREE: <span>ADVANCED</span></>;
                    break;
                default:
                    output = <>LEVEL ONE: <span>BEGINNER</span></>;
            }

            return output;
        }

        return (
            <div className={"level" + `${subLevelShow && mobileSublevel ? "" : " mobo-level"}`}>
                <div onClick={() => {toggleSection()}} className="details-level">
                    {subLevelShow ? (
                        <img src={require("./../images/add-sign" + reverse + ".png")} alt="details level" />
                    ) : (
                        <img className="cross-icon" src={require("./../images/cross-icon" + reverse + ".png")} alt="details level"/>
                    )}
                </div>
                <h4 className="train-name">{name}</h4>
                <p className="train-level">{currentLevelHeading()}</p>
                <div className="level-bar">
                    <span className="level-points beginner" />
                    <span className="level-points medium" />
                    <span className="level-points advance" />
                    <div className="level-value" style={{width: `${levelPercentage}` + "%"}} />
                </div>
                {subLevelShow ? (
                    ""
                ) : (
                    <div className={"sub-levels" + (activeDemo ? ' demo-playing' : '')}>
                        {categories[name].map((item, key) => (
                            <div key={key} className={"exercise-demo demo-" + this.sanitizeName(item.exercise_name) + (this.sanitizeName(item.exercise_name) === activeDemo ? ' active' : '') }>
                                <div className="sub-level">
                                    <div
                                        className="details-level"
                                        onClick={() => {
                                            let self = this;
                                            clearTimeout(activeDemoTimeout);
                                            playDemonstration(item.animName + item.currentLevel);
                                            this.setState({activeDemo: this.sanitizeName(item.exercise_name)})

                                            // Clear the active demo state after 12 seconds
                                            activeDemoTimeout = setTimeout(function(){
                                                self.setState({activeDemo: null})
                                            }, 12000);
                                        }}
                                    >
                                        <img
                                            src={require("./../images/play-level" + reverse + ".png")}
                                            alt="play level"
                                        />
                                    </div>
                                    <h5 className="workout-name">{item.exercise_name}</h5>
                                    <div className="level-bar">
                                        <span className="level-points advance" />
                                        <div className="level-value" style={{width: `${(item.score * 100) / 10000}` + "%"}} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps)(EvolutionLevel)