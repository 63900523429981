import {ON_CHANGE_TRAIN_ACTION, ON_FETCH_DATA_SUCCESS, ON_CHANGE_SHOE_TYPE, ON_TOGGLE_SHOE, ON_USER_ACTIVE} from "./types"
import axios from "axios"
import { API } from "../utils/const"
import { Categories, Exercises } from "../utils/helper"

export const onFetchData = () => {
  return dispatch => {
    axios
      .post(`${API}/getCategories`)
      .then(res => {
        fetchExercises(res.data, dispatch)
      })
      .catch(err => {
        console.error(err.message)
      })
  }
}

const fetchExercises = (categories, dispatch) => {
  axios
    .post(`${API}/getExercises`)
    .then(res => {
      dispatch(
        onSuccess(
          Categories(res.data, categories),
          Exercises(res.data),
          categories
        )
      )
    })
    .catch(err => {
      console.error(err.message)
    })
}

//success
const onSuccess = (Cat, Exr, CatType) => {
  var newExr = Exr

  Object.keys(Exr).map((key, item) => {
    if (Exr[key].exercise_name === "Cross Lunge to Squat") {
      newExr[key].exercise_name = "Cross Lunge"
    }
  })
  return {
    type: ON_FETCH_DATA_SUCCESS,
    category: Cat,
    exercise: newExr,
    categoryType: CatType
  }
}


export const IncrementScore=(data)=>{
  return dispatch => {
    axios
      .post(`${API}/incrementScore`,data)
      .then(res => {
        console.log("increment score updated success",res.data)
      })
      .catch(err => {
        console.error("increment score updating error",err.message)
      })
  }
}

export const checkUserActive=()=>{
  return {
    type: ON_USER_ACTIVE,
  }
}

export const onChangeShoeType= (shoeType) => {
  console.log('SHOE TYPE:' + shoeType);
  return {
    type: ON_CHANGE_SHOE_TYPE,
    payload: shoeType
  }
}


export const onTrainAction=(val)=>{
  return {
    type: ON_CHANGE_TRAIN_ACTION,
    isChange: val
  }
}


export const onToggleShoe=(gender)=>{
  return dispatch=>{
    dispatch({
      type:ON_TOGGLE_SHOE,
      shoe:gender
    })
  }
}
