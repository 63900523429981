import React, { Component } from "react"
import {Footer,
  Header,
  Nav,
  PopUp,
  SelectBackground,
  SelectShoe} from "./../components"
import { playSelectColor, showScene, hideScene } from "./../js/sceneManager"
import { connect } from "react-redux"
import {Link} from "react-router-dom";
import {getCameraPermission} from "../utils/helper";

class StartTrain extends Component {
  state = {
    isPopUp: false
  };

  handelPopUp = () => {
    if(getCameraPermission()){
      this.props.history.push("/start-training",{
        ...this.props.location.state
      });
    } else {
      this.setState({
        isPopUp: !this.props.isUserActive
      })
      if (!this.state.isPopUp) {
        hideScene()
      } else {
        showScene()
      }
    }
  };

  componentDidMount() {
    playSelectColor()
  }

  render() {
    const { handelPopUp } = this
    const { isPopUp } = this.state
    const trainingName = this.props.match.params.name
    const { color, chooseColor, shoeType } = this.props

    return (
      <>
        <Header />
        <Nav props={this.props} />
        <section
          className={
            "start-train " + `${color}` + " " + `${isPopUp ? "blur" : ""}`
          }
        >
          <div
            className={
              "training-header " + `${this.props.isTrainActive ? " fade" : ""}`
            }
          >
            <span className="title">TRAIN ASTRO</span>
            <h2 className="heading">{trainingName} TRAINING</h2>
          </div>
          <div
            className={
              "btn-wrapper" + " " + `${this.props.isTrainActive ? "fade" : ""}`
            }
          >
            <SelectShoe color={color} shoeType={shoeType} chooseColor={clr => {}} />
            <SelectBackground
              color={color}
              chooseColor={clr => chooseColor(clr)}
            />
            <div className="pop-btn">
              {this.props.isUserActive ?
                  <Link className="astro-btn"
                        to={{
                          pathname: "/start-training",
                          state: {
                            ...this.props.location.state
                          }
                        }}
                  >NEXT</Link> :
                  <button onClick={handelPopUp} className="astro-btn">
                    NEXT
                  </button>
              }
            </div>
          </div>
        </section>
        <Footer />
        {isPopUp  ? (
          <PopUp
            selectedExercises={this.props.location.state}
            color={color}
            handelPopUp={handelPopUp}
          />
        ) : (
          ""
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive,
    isUserActive : state.isUserActive
  }
}

export default connect(mapStateToProps)(StartTrain)
