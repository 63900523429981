import * as BABYLON from "babylonjs"
import "babylonjs-loaders"
import femaleLeft from "./../images/Puma_Female_Left_low.jpg"
import femaleRight from "./../images/Puma_Female_Right_low.jpg"
import maleLeft from "./../images/Sneaker_Male_Left_low.jpg"
import maleRight from "./../images/Sneaker_Male_Right_low.jpg"
import { queueAudioClip } from "./audioManager"
import Debug from "./debug"
import {getBackgroundColor, getShoeType} from "../utils/helper";

let _scene,
  _engine,
  _canvas,
  _camera,
  sceneContainer,
  leftShoeMaterial,
  rightShoeMaterial,
  sceneFileName = "Astro0724.glb",
  totalAnimations = 0,
  animationQueue = [],
  currentAnimation = {},
  debug = new Debug(false, false, 'Scene Manager');

/**
 * Creates the scene object and applies the default settings
 *
 * @returns {module:babylonjs/scene.Scene}
 */
function createScene() {
  debug.log("Create the scene");

  const scene = new BABYLON.Scene(_engine);

  // Create a basic light, aiming 0,1,0 - meaning, to the sky.
  const light = new BABYLON.HemisphericLight(
    "light1",
    new BABYLON.Vector3(0, 1, 0),
    _scene
  );
  light.intensity = 3;

  // Create an ArcRotateCamera
  _camera = new BABYLON.ArcRotateCamera(
    "camera",
    1.6,
    1.7,
    setSize(),
    new BABYLON.Vector3(-0.171, 89.473, 4.933),
    _scene
  );

  // This is really important to tell Babylon.js to use decomposeLerp and matrix interpolation
  BABYLON.Animation.AllowMatricesInterpolation = true;

  // This attaches the camera to the canvas
  _camera.attachControl(_canvas, true);

  // Make the background transparent
  scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

  BABYLON.SceneLoader.ImportMesh(
    "",
    "/meshes/",
    sceneFileName,
    scene,
    function() {
      debug.log("Scene loaded successfully");

      // Enable animation blending for all animations
      scene.animationPropertiesOverride = new BABYLON.AnimationPropertiesOverride();
      scene.animationPropertiesOverride.enableBlending = true;
      scene.animationPropertiesOverride.blendingSpeed = 1;
      scene.animationPropertiesOverride.loopMode = 1;

      // Remove sheen from sneakers and set the default shoe
      leftShoeMaterial = _scene.getMaterialByName("Sneaker_Left_M");
      rightShoeMaterial = _scene.getMaterialByName("Sneaker_Right_M");
      leftShoeMaterial.roughness = 1;
      rightShoeMaterial.roughness = 1;

      scene.removeCamera(scene.getCameraByName("persp1"));

      // Get the total number of animations
      totalAnimations = scene.animationGroups.length;

      stopAllAnimations();
      setColor(getBackgroundColor());
      setShoe(getShoeType());
      startAnimation();
    }
  );

  return scene;
}

/**
 * Starts the animation for the first time based on the current page
 */
function startAnimation() {
  let path = window.location.pathname.replace(/^\/training\/(.*?)\//g, "/"); // Remove the dynamic path name

  switch (path) {
  case "/start-training":
    playStartTraining();
    break;
  case "/start-train":
    playSelectColor();
    break;
  case "/evolution":
    playEvolution();
    break;
  case "/":
    playIntro();
  }
}

/**
 * Plays the intro animation sequence
 */
function playIntro() {
  debug.log("Play the intro animation sequence");
  if (_scene) {
    hideScene();

    // Add a half second delay to prevent the animation from jumping on the screen while changing sections
    setTimeout(function() {
      // Set the Intro animation start frame to where Astro begins to wave if screen is mobile size and in portrait mode
      let start = (window.innerWidth <= 640 && window.innerWidth < window.innerHeight) ? 3.37 : 0;

      placeAstro();
      setSize("large");
      queueAnimation("Intro", true, false, start);
      queueAnimation("SecondaryIdle");
      queueAnimation("MainIdle");
      showScene();
      queueAudioClip("astro-intro");
    }, 500);
  }
}


/**
 * Initiates the animation sequence for the evolution page
 */
function playEvolution() {
  debug.log("Play the evolution animation sequence");

  if (_scene) {
    setPosition("right");
    setSize("small");
    queueAnimation("SecondaryIdle", true);
    queueAnimation("MainIdle");
    showScene();
  }
}

/**
 * Initiates the animation sequence for the Select Color screen
 */
function playSelectColor() {
  if (_scene) {
    setSize("small");
    setPosition();
    queueAnimation("SecondaryIdle", true);
    queueAnimation("MainIdle");
    showScene();
  }
}

/**
 * Initiates the animation sequence for the Start Training page
 */
function playStartTraining() {
  if (_scene) {
    setSize("large");
    placeAstro();
    queueAnimation("MainIdle");
    showScene();
  }
}

/**
 * Initiates the animation sequence for the requested demonstration on the Evolution page
 *
 * @param animation (string) - The animation ID
 */
function playDemonstration(animation) {
  queueAnimation(animation, true);
  queueAnimation("MainIdle");
  queueAnimation("SecondaryIdle");
  queueAnimation("MainIdle");
}

/**
 * Change the texture of Astro's shoes
 *
 * @param type (string) - The specified shoe type. Accepted values are male and female
 */
function setShoe(type) {
  debug.log("Change Astro's shoes", type);

  if (_scene) {
    let leftShoeTexture, rightShoeTexture;

    switch (type) {
    case "female":
      leftShoeTexture = new BABYLON.Texture(femaleLeft, _scene, false, false);
      rightShoeTexture = new BABYLON.Texture(
        femaleRight,
        _scene,
        false,
        false
      );
      break;
    case "male":
      leftShoeTexture = new BABYLON.Texture(maleLeft, _scene, false, false);
      rightShoeTexture = new BABYLON.Texture(maleRight, _scene, false, false);
      break;
    }

    leftShoeMaterial.emissiveTexture = leftShoeTexture;
    rightShoeMaterial.emissiveTexture = rightShoeTexture;
  }
}

/**
 * Change Astro's color scheme based on the selected color scheme
 *
 * @param background (string) - The background color class name
 */
function setColor(background) {
  debug.log("Change Astro's limb color");

  if (_scene) {
    let material = _scene.getMaterialByName("AstroGreen_M"),
      colors = {
        red: new BABYLON.Color3(1, 0.059, 0.059),
        blue: new BABYLON.Color3(0, 0.631, 0.667),
        pink: new BABYLON.Color3(1, 0.173, 0.212),
        green: new BABYLON.Color3(0.655, 1, 0),
        orange: new BABYLON.Color3(1, 0.349, 0.059),
        teal: new BABYLON.Color3(0, 0.631, 0.667)
      },
      color;

    switch (background) {
    case "home-red":
      color = colors.green;
      break;
    case "home-blue":
      color = colors.orange;
      break;
    case "home-pink":
      color = colors.blue;
      break;
    case "home-green":
      color = colors.red;
      break;
    case "home-black":
      color = colors.green;
      break;
    case "home-orange":
      color = colors.teal;
      break;
    case "home-gray":
      color = colors.pink;
      break;
    default:
      color = colors.green;
    }

    material.emissiveColor = color
  }
}

/**
 * Change Astro's position on the screen
 *
 * @param position (string) - The specified position. Accepted values are right, top and top-landscape
 */
function setPosition(position) {
  debug.log("Set the position of the scene to " + position);

  if (_canvas) {
    _canvas.classList.remove("right", "top", "top-landscape");

    switch (position) {
    case "right":
      _canvas.classList.add("right");
      break;
    case "top":
      _canvas.classList.add("top");
      break;
    case "top-landscape":
      _canvas.classList.add("top-landscape");
      break;
    }
  }
}

/**
 * Changes Astro's position on the screen when using a mobile device
 *
 * @param categoryExpanded (boolean) - Is the exercise category expanded
 * @param refresh (boolean) - Resize the the scene
 */
function placeAstro(refresh) {
  let categoryExpanded = !!document.querySelector(".mobile-expanded");

  // Check if user is on the home page
  if(window.location.pathname === '/'){
    // Check if the screen is tablet size or smaller and is in portrait mode
    if(window.innerWidth <= 640 && window.innerWidth < window.innerHeight){
      setPosition('right');
    } else {
      setPosition();
    }
  }

  // Check if user is on the start training page
  if(window.location.pathname === '/start-training') {
    // Check if the screen is in portrait or landscape mode
    if(window.innerWidth > window.innerHeight){
      // Check if the screen is laptop size or smaller and is in landscape mode
      if(window.innerWidth >= 1024 && window.innerWidth <= 1366){
        setPosition('right');
      } else {
        setPosition();
      }
    } else {
      // Check if the screen is laptop size or smaller and is in landscape mode
      if(window.innerWidth >= 768){
        setPosition('right');
      } else {
        setPosition();
      }
    }
  }

  // Check if user is on the evolution page
  if (window.location.pathname === "/evolution") {
    // Check if the screen is tablet size or smaller
    if (window.innerWidth <= 768) {
      // Check if the evolution category is expanded
      if (categoryExpanded) {
        debug.log("Move Astro to the top");

        // Check if screen is portrait or landscape
        if (window.innerWidth < window.innerHeight) {
          setPosition("top");
        } else {
          setPosition("top-landscape");
        }
      } else {
        debug.log("Reset Astro's position");
        setPosition("right");
      }
    } else {
      debug.log("Reset Astro's position");
      setPosition("right");
    }

    // Resize the scene if not triggered by window resize
    if (refresh) {
      refreshScene();
    }
  }
}

/**
 * Changes Astro's size within the scene
 *
 * @param size (string) - The specified size
 * @returns {number}
 */
function setSize(size) {
  debug.log("Set the size of the scene to " + size);
  let radius = setMobileSize();

  switch (size) {
  case "small":
    radius = 350;
    break;
  case "large":
    radius = radius ? radius : 275;
    break;
  default:
    radius = 350;
  }

  if (_scene) {
    _scene.activeCamera.radius = radius;
  }

  return radius;
}

/**
 * Changes Astro's size within the scene when using a mobile device
 *
 * @returns {boolean|number}
 */
function setMobileSize() {
  let radius;

  // Check if screen is desktop size or smaller
  if (window.innerWidth <= 1024) {
    debug.log("Device is mobile");

    // Check if screen is in portrait or landscape
    if (window.innerWidth < window.innerHeight) {
      debug.log("Device is in portrait mode");

      radius = 320;
    } else {
      debug.log("Device is in landscape mode");
      radius = 300;
    }
  } else {
    radius = false;
  }

  return radius;
}

/**
 * Creates a request for an animation to be played
 *
 * @param animationID (string) - The animation ID. Refer to `getAnimationDefaults()` to see what IDs are available
 * @param forceStop (boolean) - Force the animation currently playing to stop immediately
 * @param loop (boolean) - Loop the animation
 * @param from (float) - The frame to start the animation from
 * @param callback (function) - Fires after the animation has finished playing or the first loop has ended
 */
function queueAnimation(animationID, forceStop, loop, from, callback) {
  if (totalAnimations) {
    debug.log("Queue animation: " + animationID);

    let request = getAnimationDefaults(animationID, forceStop, loop, from, callback);
    debug.log(request);

    if (request) {
      animationQueue.push(request);

      // If no animation is playing then play the next in queue
      if (!currentAnimation.isPlaying || forceStop) {
        if (forceStop) {
          animationQueue = [animationQueue.pop()];
        }
        playNextAnimation();
      }
    }
  } else {
    debug.error("There are no animation groups");
  }
}

/**
 * Plays the next animation in queue
 */
function playNextAnimation() {
  refreshScene();

  // Continue if there is an item in the queue
  if (animationQueue.length) {
    debug.log("Begin the next animation");

    let nextAnimation = animationQueue[0];

    // Force the current animation to stop if specified and prevent it's callback from firing
    if (currentAnimation.isPlaying) {
      stopCurrentAnimation();
    }

    // Set the new current animation
    currentAnimation = _scene.animationGroups[nextAnimation.index];

    // Play the animation
    currentAnimation.start(nextAnimation.loop, 1, nextAnimation.from);

    // Set the animation callbacks
    setAnimationCallback(nextAnimation.callback);

    // Remove item from the queue
    animationQueue.shift();
  } else {
    debug.error("Nothing to play");
  }
}

/**
 * Stops all animations groups in the scene that are currently playing
 */
function stopAllAnimations() {
  debug.log("Stop all animations");
  _scene.animationGroups.forEach(function(animation) {
    if (animation.isPlaying) {
      animation.stop();
    }
  })
}

/**
 * Stops the animation that is currently playing
 */
function stopCurrentAnimation() {
  removeAnimationCallback();
  currentAnimation.stop();
}

/**
 * Fires when the current animation or animation loop has has finished playing
 *
 * @param callback (function) - The queued animation callback request
 */
function onAnimationEnd(callback) {
  if (animationQueue.length) {
    debug.log("Current animation has ended and something is in the queue");

    // Start the new animation
    playNextAnimation();
  }

  // Check if there is a callback
  if (typeof callback !== "undefined") {
    // Check if the callback is indeed a function
    if (typeof callback === "function") {
      debug.log("Run the animation callback function");
      callback();
    } else {
      debug.error("callback argument is not a function");
    }
  }
}

/**
 * Sets the scene's animation callback based on if the animation is a loop or not
 *
 * @param callback (function) - The queued animation callback request
 */
function setAnimationCallback(callback) {
  // Check if the requested animation is a loop or not
  if (currentAnimation.loopAnimation) {
    debug.log("the current animation is a loop");
    currentAnimation.animatables[0].onAnimationLoopObservable.add(function() {
      debug.log("The animation loop has ended");
      onAnimationEnd(callback);
    })
  } else {
    debug.log("the current animation is NOT a loop");
    currentAnimation.onAnimationGroupEndObservable.add(function() {
      onAnimationEnd(callback);
    })
  }
}

/**
 * Removes the scene's animation callbacks
 */
function removeAnimationCallback() {
  currentAnimation.onAnimationLoopObservable.clear();
  currentAnimation.onAnimationGroupEndObservable.clear();
}


/**
 * Creates a screenshot
 */
function captureScene(){
  debug.log('Capture screenshot of scene');

  let screenshot;

  BABYLON.Tools.CreateScreenshotUsingRenderTarget(_engine, _camera, {width: window.innerWidth, height: window.innerHeight}, function(data){

    // Create an image from the screenshot data
    screenshot = data;
  });

  return screenshot;
}

/**
 * Makes the scene visible
 */
function showScene() {
  debug.log("Display the scene");

  // Add a slight delay when displaying the scene to avoid jumps between animations
  setTimeout(function() {
    if (sceneContainer) {
      sceneContainer.classList.add("active");
    }
  }, 100);
}

/**
 * Hides the scene from view
 */
function hideScene() {
  debug.log("Hide the scene");
  if (sceneContainer) {
    sceneContainer.classList.remove("active");
  }
}

/**
 * Update the scene whenever the screen size changes
 */
function resizeScene() {
  if (_engine) {
    setSize();
    placeAstro();
    refreshScene();
  }
}

/**
 * Refresh the scene after a small delay so that it doesn't appear skewed
 */
function refreshScene() { 
  setTimeout(function() {
    _engine.resize()
  }, 200)
}

/**
 * Renders the Babylon.js scene
 *
 * @param e (event) - The scene event args
 */
function renderScene(e: SceneEventArgs) {
  const { canvas, engine } = e;

  _canvas = canvas;
  _engine = engine;
  sceneContainer = document.getElementById("scene-container");

  debug.log("Render the scene");
  _scene = createScene();
  _engine.runRenderLoop(function() {
    if (_scene) {
      _scene.render();
    }
  });
  // window.sceneManager = _scene // Make the scene accessible to the console
  // window.queueAnimation = (animationID, forceStop, loop, callback) => {queueAnimation(animationID, forceStop, loop, callback)};
  // window.captureScene = () => {captureScene()};
  debug.log(_scene); // Show the scene output as a reference
}

/**
 * Gets the default settings for the requested animation.
 * It should be noted that index field refers to the corresponding animationGroup in the scene's loaded mesh.
 * Therefore, a new case needs to be added to the switch statement which targets the new animationGroup's index or else the animationGroup will not be found.
 *
 * @param id (string) - The animation ID
 * @param forceStop (boolean) - Force the animation currently playing to stop immediately
 * @param loop (boolean) - Loop the animation
 * @param from (number) - The frame from which to start the animation
 * @param callback (function) - Fires after the animation has finished playing or the first loop has ended
 *
 * @return {object}
 */
function getAnimationDefaults(id, forceStop, loop, from, callback) {
  let output = {};

  switch (id) {
  case "Intro":
    output = {
      index: 0,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "MainIdle":
    output = {
      index: 1,
      loop: typeof loop !== "undefined" ? loop : true,
      forceStop: forceStop
    };
    break;
  case "SecondaryIdle":
    output = {
      index: 2,
      loop: typeof loop !== "undefined" ? loop : true,
      forceStop: forceStop
    };
    break;
  case "StudyIdle":
    output = {
      index: 3,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Shrug":
    output = {
      index: 4,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BobWeave1":
    output = {
      index: 5,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BobWeave2":
    output = {
      index: 6,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BobWeave3":
    output = {
      index: 7,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "JumpJack1":
    output = {
      index: 8,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "JumpJack2":
    output = {
      index: 9,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "JumpJack3":
    output = {
      index: 10,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Squat1":
    output = {
      index: 11,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Squat3":
    output = {
      index: 12,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Squat2":
    output = {
      index: 13,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "StandingLongJump1":
    output = {
      index: 14,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "StandingLongJump2":
    output = {
      index: 15,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "StandingLongJump3":
    output = {
      index: 16,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "PushUp1":
    output = {
      index: 17,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "PushUp2":
    output = {
      index: 18,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "PushUp3":
    output = {
      index: 19,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Situps1":
    output = {
      index: 20,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Situps2":
    output = {
      index: 21,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Situps3":
    output = {
      index: 22,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Lunge1":
    output = {
      index: 23,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Lunge2":
    output = {
      index: 24,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Lunge3":
    output = {
      index: 25,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "FrontKick1":
    output = {
      index: 26,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "FrontKick2":
    output = {
      index: 27,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "FrontKick3":
    output = {
      index: 28,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Burpee1":
    output = {
      index: 29,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Burpee2":
    output = {
      index: 30,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "Burpee3":
    output = {
      index: 31,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "LateralShuffle1":
    output = {
      index: 32,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "LateralShuffle2":
    output = {
      index: 33,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "LateralShuffle3":
    output = {
      index: 34,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "HighKnees1":
    output = {
      index: 35,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "HighKnees3":
    output = {
      index: 36,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "HighKnees2":
    output = {
      index: 37,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BackScale1":
    output = {
      index: 38,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BackScale2":
    output = {
      index: 39,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BackScale3":
    output = {
      index: 40,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "DownwardDog1":
    output = {
      index: 41,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "DownwardDog2":
    output = {
      index: 42,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "DownwardDog3":
    output = {
      index: 43,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "ExtendedTrianglePose1":
    output = {
      index: 44,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "ExtendedTrianglePose2":
    output = {
      index: 45,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "ExtendedTrianglePose3":
    output = {
      index: 46,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TreePose1":
    output = {
      index: 47,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TreePose2":
    output = {
      index: 48,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TreePose3":
    output = {
      index: 49,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BackLungeFrontKick1":
    output = {
      index: 50,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BackLungeFrontKick2":
    output = {
      index: 51,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "BackLungeFrontKick3":
    output = {
      index: 52,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "CrossLungeToSquat1":
    output = {
      index: 53,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "CrossLungeToSquat2":
    output = {
      index: 54,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "CrossLungeToSquat3":
    output = {
      index: 55,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TheFloss1":
    output = {
      index: 56,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TheFloss2":
    output = {
      index: 57,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TheFloss3":
    output = {
      index: 58,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "PowerSkipping1":
    output = {
      index: 59,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "PowerSkipping2":
    output = {
      index: 60,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "PowerSkipping3":
    output = {
      index: 61,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "SquatJump1":
    output = {
      index: 62,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "SquatJump2":
    output = {
      index: 63,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "SquatJump3":
    output = {
      index: 64,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "WarmUp":
    output = {
      index: 65,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "StudyIdleAlt":
    output = {
      index: 66,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "RunningMan1":
    output = {
      index: 67,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "RunningMan2":
    output = {
      index: 68,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "RunningMan3":
    output = {
      index: 69,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "LateralJump1":
    output = {
      index: 70,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "LateralJump2":
    output = {
      index: 71,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "LateralJump3":
    output = {
      index: 72,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "ReverseLungeKneeUps1":
    output = {
      index: 73,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "ReverseLungeKneeUps2":
    output = {
      index: 74,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "ReverseLungeKneeUps3":
    output = {
      index: 75,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TheCabbage1":
    output = {
      index: 76,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TheCabbage2":
    output = {
      index: 77,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  case "TheCabbage3":
    output = {
      index: 78,
      loop: typeof loop !== "undefined" ? loop : false,
      forceStop: forceStop
    };
    break;
  default:
    output = false;
  }
  output.from = typeof from !== "undefined" ? from : 0;
  output.callback = callback;

  if (!output) {
    debug.error("Requested animation " + id + " not found");
  }

  return output;
}


export {
  resizeScene, renderScene, queueAnimation, playIntro, playEvolution, playSelectColor, playStartTraining, playDemonstration, stopAllAnimations, showScene, hideScene, captureScene, setSize, setPosition, placeAstro, setColor, setShoe
}
