import { ON_FETCH_DATA_SUCCESS, ON_CHANGE_TRAIN_ACTION,ON_USER_ACTIVE, ON_CHANGE_SHOE_TYPE } from "./types"
const initialState = {
  categories: {
  },
  exercises: {
  },
  categoryType: [],
  isTrainActive: false,
  isUserActive:false,
  shoeType: "male"
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ON_FETCH_DATA_SUCCESS:
    return {
      ...state,
      categories: action.category,
      exercises: action.exercise,
      categoryType: action.categoryType
    }

  case ON_CHANGE_TRAIN_ACTION:
    return {
      ...state,
      isTrainActive: action.isChange
    }

  case ON_CHANGE_SHOE_TYPE:
    return {
      ...state,
      shoeType: action.payload
    }

    case ON_USER_ACTIVE:
      return {
        ...state,
        isUserActive: true
      }

  default:
    return {
      ...state
    }
  }
}

export default reducer
