import React, { Component } from "react"
import { init as detectionInit, setExercise } from "../js/detection.js"

export default class SelectExercise extends Component {
  state = {
    isSquat1: false,
    exercisesQueue: {
      1: [],
      2: [],
      3: []
    }
  };

  addQueueHandel = (diff, index, exercise) => {
    const { activeExercises, makeActive } = this.props
    if (activeExercises.length < 3 || activeExercises.includes(exercise)) {
      makeActive(exercise)
    }
  };

  divideInDif = exercises => {
    let results = {
      1: [],
      2: [],
      3: []
    }
    exercises.map(e => {
      results[e.difficulty].push(e)
    })
    return results
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.activeExercises.length > 0 &&
      nextProps.activeExercises !== this.props.activeExercises
    ) {
      // setExercise(this.props.activeExercises[0]) //This needs to be moved so that we can cycle through the selected exercises
    }
  }

  render() {
    const { addQueueHandel, divideInDif } = this
    const { exercisesName, activeExercises } = this.props
    const reverse = (this.props.color === 'home-black') ? '-reverse' : '';
    let exercise = exercisesName || []
    exercise = divideInDif(exercise || [])
    return (
      <>
        {exercise[1].length ? <h3 className="squat-level">Beginner</h3> : null}
        {exercise[1] &&
          exercise[1].map((exer, index) => {
            return (
              <div className="squat-queues" key={index}>
                <h4 className="queue-name">{exer.exercise_name}</h4>
                <div className="adding-queue">
                  {activeExercises.includes(exer) ? (
                    <span className="queue-text">Remove</span>
                  ) : (
                    <span className="queue-text">Add to Queue</span>
                  )}
                  <span
                    onClick={() => addQueueHandel(1, index, exer)}
                    className={
                      "queue-icon " +
                      `${activeExercises.includes(exer) ? "remove-icon" : ""}`
                    }
                  >
                    {activeExercises.includes(exer) ? (
                      <img
                        src={require("./../images/remove-icon" + reverse + ".png")}
                        alt="remove sign"
                      />
                    ) : (
                      <img
                        src={require("./../images/add-sign.png")}
                        alt="add sign"
                      />
                    )}
                  </span>
                </div>
              </div>
            )
          })}
        {exercise[2].length ? (
          <h3 className="squat-level">Intermediate</h3>
        ) : null}
        {exercise[2] &&
          exercise[2].map((exer, index) => {
            return (
              <div className="squat-queues" key={index}>
                <h4 className="queue-name">{exer.exercise_name}</h4>
                <div className="adding-queue">
                  {activeExercises.includes(exer) ? (
                    <span className="queue-text">Remove</span>
                  ) : (
                    <span className="queue-text">Add to Queue</span>
                  )}
                  <span
                    onClick={() => addQueueHandel(2, index, exer)}
                    className={
                      "queue-icon " +
                      `${activeExercises.includes(exer) ? "remove-icon" : ""}`
                    }
                  >
                    {activeExercises.includes(exer) ? (
                      <img
                        src={require("./../images/remove-icon" + reverse + ".png")}
                        alt="remove sign"
                      />
                    ) : (
                      <img
                        src={require("./../images/add-sign.png")}
                        alt="add sign"
                      />
                    )}
                  </span>
                </div>
              </div>
            )
          })}
        {exercise[3].length ? <h3 className="squat-level">Advanced</h3> : null}
        {exercise[3] &&
          exercise[3].map((exer, index) => {
            return (
              <div className="squat-queues" key={index}>
                <h4 className="queue-name">{exer.exercise_name}</h4>
                <div className="adding-queue">
                  {activeExercises.includes(exer) ? (
                    <span className="queue-text">Remove</span>
                  ) : (
                    <span className="queue-text">Add to Queue</span>
                  )}
                  <span
                    onClick={() => addQueueHandel(3, index, exer)}
                    className={
                      "queue-icon " +
                      `${activeExercises.includes(exer) ? "remove-icon" : ""}`
                    }
                  >
                    {activeExercises.includes(exer) ? (
                      <img
                        src={require("./../images/remove-icon" + reverse + ".png")}
                        alt="remove sign"
                      />
                    ) : (
                      <img
                        src={require("./../images/add-sign.png")}
                        alt="add sign"
                      />
                    )}
                  </span>
                </div>
              </div>
            )
          })}
      </>
    )
  }
}
