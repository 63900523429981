import React from "react";
import ThankYou from "./../../images/thank-you.png";

export default class Loader extends React.Component {
    render (){
        return (
            <div className="timer">
                <svg viewBox="0 0 64 64" className="landScape-hide">
                    <circle className="timer-circle" r="49%" cx="50%" cy="50%" style={{strokeDasharray: 197}} />
                </svg>
                <div className="timer-text">
                    <h2 className="step2-heading"><img src={ThankYou} alt="Thanks for Training Astro"/></h2>
                </div>
            </div>
        )
    }
}