import React, { Component } from "react"
import { Link } from "react-router-dom"
import TrainAstro from "./TrainAstro"
import { connect } from "react-redux"
import { TrainCat } from "../utils/helper"
import { showScene, hideScene } from "./../js/sceneManager"
import { queueAudioClip } from "./../js/audioManager"
import {onTrainAction} from "../store/actions"

class Nav extends Component {
  state = {
    isEvolutionActive: false,
    isWatchActive: false,
    isPumaActive: false,
    shadow: false,
    isHomeActive: false,
    pathname: "",
    isMobActive: false,
    trains: [
      {
        trainName: "Strength",
        trainBg: "gray-bg2",
        trainImage: "STRENGTH-COLOUR.png"
      },
      {
        trainName: "power",
        trainBg: "pink-bg2",
        trainImage: "POWER.png"
      },
      {
        trainName: "agility",
        trainBg: "blue-bg2",
        trainImage: "AGILITY_COLOUR.png"
      },
      {
        trainName: "speed",
        trainBg: "orange-bg2",
        trainImage: "SPEED.png"
      },
      {
        trainName: "yoga",
        trainBg: "green-bg2",
        trainImage: "YOGA.png"
      },
      {
        trainName: "combat",
        trainBg: "red-bg2",
        trainImage: "COMBAT.png"
      }
    ]
  };

  navHandel = () => {
    this.setState({
      isMobActive: !this.state.isMobActive
    })
  };

  activeHandel = () => {
    this.setState({
      isHomeActive: !this.state.isHomeActive,
      isMobActive: !this.state.isMobActive
    })

    this.toggleTrainNav(!this.props.isTrainActive)
  };

  selectCategory = () => {
    this.setState({
      // isHomeActive: !this.state.isHomeActive,
      // isMobActive: !this.state.isMobActive
    })
    document.documentElement.scrollTop = 0;
    this.toggleTrainNav(!this.props.isTrainActive)
    console.log('KEEP THE DAMN MENU CLOSED');
  };

  toggleTrainNav = show => {
    this.props.OnChangeTrainAction(show)
  };

  toggleScene = () => {
    const path = window.location.pathname

    if (
      path === "/" ||
      path === "/evolution" ||
      path === "/start-training" ||
      path.split("/")[3] === "start-train"
    ) {
      if (this.props.isTrainActive) {
        showScene()
      } else {
        hideScene()
      }
    }
  };

  render() {
    const { activeHandel, navHandel, toggleScene, selectCategory } = this
    const path = this.props.props.history.location.pathname
    const { isHomeActive, isMobActive } = this.state
    const { isTrainActive } = this.props
    return (
      <React.Fragment>
        <nav
          className={
            "nav " +
            `${path == "/" && !isHomeActive ? "" : "nav-active"}` +
            `${isMobActive ? " mob-nav-active" : ""}`
          }
          data-html2canvas-ignore="true"
        >
          <a
            onClick={() => {
              activeHandel()
              toggleScene()
              queueAudioClip("button-1")
            }}
            className={
              "orange-bg " +
              `${
                isTrainActive || path.split("/")[1] == "training"
                  ? "active"
                  : ""
              }`
            }
          >
            <img src={require("./../images/train-icon.png")} alt="train-icon" />
            <span>Train</span> <span>Help Train Astro</span>
          </a>
          <Link
            onClick={() => {
              this.toggleTrainNav(false)
              showScene()
              queueAudioClip("button-2")
            }}
            className={
              "gray-bg " +
              `${!isTrainActive && path == "/evolution" ? "active" : ""}`
            }
            to="/evolution"
          >
            <img
              src={require("./../images/evolution-icon.png")}
              alt="train-icon"
            />
            <span>Evolution</span>
            <span>THE EVOLUTION OF ASTRO</span>
          </Link>
          <Link
            onClick={() => {
              this.toggleTrainNav()
              queueAudioClip("button-3")
            }}
            className={
              "pink-bg " +
              `${!isTrainActive && path == "/watch" ? "active" : ""}`
            }
            to="/watch"
          >
            <img src={require("./../images/watch-icon.png")} alt="train-icon" />
            <span>Watch</span>
            <span>WATCH ATHLETES TRAIN ASTRO</span>
          </Link>
          <a
            className={
              "black-bg " +
              `${!isTrainActive && path == "/puma.com" ? "active" : ""}`
            }
            onClick={() => {
              queueAudioClip("button-4")
            }}
            href="https://au.puma.com"
            _target="_blank"
          >
            <img
              src={require("./../images/nav-puma-logo.png")}
              alt="train-icon"
            />
            <span>Puma.com</span>
            <span>GO TO PUMA.COM</span>
          </a>
          <button className="menu-btn" onClick={navHandel}>
            Menu
          </button>
          <button className="close-btn" onClick={navHandel}>
            <img src={require("./../images/cross-icon.png")} alt="cross" />{" "}
            close
          </button>
        </nav>

        <section className={"train " + `${isTrainActive ? "anime-train" : ""}`}>
          <div className="train-container">
            {TrainCat(this.props.categoryType).map((train, index) => {
              return (
                <TrainAstro
                  key={index}
                  trainName={train.exercise_category}
                  trainImage={train.trainImage}
                  selectCategory={selectCategory}
                  trainImage2={train.trainImage2}
                />
              )
            })}
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    exercises: state.exercises,
    categoryType: state.categoryType,
    isTrainActive: state.isTrainActive
  }
}

const mapDispatchToProps = dispatch => {
  return {
    OnChangeTrainAction: val => dispatch(onTrainAction(val))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav)
