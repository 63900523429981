import React, { Component } from "react"
import { Footer, Header, Nav } from "./../components"
export default class Train extends Component {
  render() {
    return (
      <>
        <Header />
        <Nav props={this.props} />
        <section className="train">
          <div className="train-container">
            <div className="train-astro">
              <span className="astro">Train Astro</span>
              <h3 className="train-name">Combat Training</h3>
            </div>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}
