import React, { Component } from "react"
import { setColor } from "./../js/sceneManager"
import { connect } from "react-redux"
import {setBackgroundColor, toggleOptions} from "../utils/helper";

class SelectBackground extends Component {
  state = {
    color: this.props.color,
    isActive: false
  };

  isActive = false;

  togelsButton = () => {
    this.setState({
      isActive: !this.state.isActive
    })

      this.isActive = !JSON.parse(window.localStorage.getItem('selectBackground'));
      window.localStorage.setItem('selectBackground', this.isActive);
      window.localStorage.setItem('selectShoe', false);
      toggleOptions();
  };

  componentDidMount() {
      window.localStorage.setItem('selectBackground', false);
  }

    componentWillUnmount() {
    this.setState({
      isActive: true
    })
    console.log("add class", this.state.isActive)
  }

  addClassHandler = classname => {
    this.setState(
      {
        color: classname
      },
      () => {
          setBackgroundColor(classname)
          setColor(classname)
          this.props.chooseColor(classname)
      }
    )
  };

  render() {
    const { addClassHandler, togelsButton } = this
    const { color } = this.state
    return (
      <div
        className={
          "bg-select " +
          `${this.props.color}` +
          " " +
          `${this.props.isTrainActive ? "hide" : ""}`
        }
      >
        <span className="bg-text" onClick={togelsButton}>
          BACKGROUND
        </span>
        <button
          onClick={() => addClassHandler("home-red")}
          type="button"
          className={
            "select-btn red-bg " +
            `${color == "home-red" ? "active " : ""}`
          }
        />
        <button
          onClick={() => addClassHandler("home-blue")}
          type="button"
          className={
            "select-btn blue-bg " +
            `${color == "home-blue" ? "active " : ""}`
          }
        />
        <button
          onClick={() => addClassHandler("home-pink")}
          className={
            "select-btn pink-bg " +
            `${color == "home-pink" ? "active " : ""}`
          }
        />
        <button
          onClick={() => addClassHandler("home-green")}
          className={
            "select-btn green-bg " +
            `${color == "home-green" ? "active " : ""}`
          }
        />
        <button
          onClick={() => addClassHandler("home-black")}
          className={
            "select-btn black-bg " +
            `${color == "home-black" ? "active " : ""}`
          }
        />
        <button
          onClick={() => addClassHandler("home-orange")}
          className={
            "select-btn orange-bg " +
            `${color == "home-orange" ? "active " : ""}`
          }
        />
        <button
          onClick={() => addClassHandler("home-gray")}
          className={
            "select-btn gray-bg " +
            `${color == "home-gray" ? "active " : ""}`
          }
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive
  }
}

export default connect(mapStateToProps)(SelectBackground)
