import React, { Component } from "react"
import { setShoe } from "./../js/sceneManager"
import { connect } from "react-redux"
import {onChangeShoeType} from "../store/actions";
import {getShoeType, setShoeType, toggleOptions} from "../utils/helper";

class SelectShoe extends Component {
  state = {
    color: this.props.color,
    currentShoe: this.props.shoeType,
    isActive: false,
  };

  isActive = false;

  togelsButton = () => {
    this.setState({
      isActive: !this.state.isActive
    })

    this.isActive = !JSON.parse(window.localStorage.getItem('selectShoe'));
    window.localStorage.setItem('selectShoe', this.isActive);
    window.localStorage.setItem('selectBackground', false);
    toggleOptions();
  };

  toggleShoe = shoe => {
    this.setState(
      {
        currentShoe: shoe
      },
      () => {
        setShoeType(shoe);
        setShoe(shoe);
        this.props.onChangeShoeType(shoe)
      }
    )
  };

  componentDidMount() {
    window.localStorage.setItem('selectShoe', false);
  }

  render() {
    const { toggleShoe, togelsButton } = this
    const { color, isActive, currentShoe } = this.state
    return (
      <div
        className={
          "shoe-select bg-select " +
          `${this.props.color}` +
          " " +
          `${this.props.isTrainActive ? "hide" : ""}`
        }
      >
        <p className="bg-text" onClick={togelsButton}>
          <span>CHANGE</span> SHOE
        </p>
        <div
          className={
            "shoe-container shoe1 " +
            `${currentShoe === "male" ? "active-shoe " : ""}`
          }
          onClick={() => {
            toggleShoe("male")
          }}
        >
          <img
            className="shoe "
            src={require("./../images/Shoe_LimePinkInside.png")}
          />
        </div>
        <div
          className={
            "shoe-container shoe2 " +
            `${currentShoe === "female" ? "active-shoe " : ""}`
          }
          onClick={() => {
            toggleShoe("female")
          }}
        >
          <img
            className="shoe "
            src={require("./../images/Shoe_BlueRedInside.png")}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChangeShoeType: (shoeType) => dispatch(onChangeShoeType(shoeType))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectShoe)
