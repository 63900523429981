import React, { Component } from "react"
import { initAudio } from "../js/audioManager"

class Audio extends Component {
  componentDidMount() {
    initAudio(this.player)
  }

  render() {
    return <audio ref={ref => (this.player = ref)} />
  }
}

export default Audio
