import React from "react";
import ArrowUp from "../../images/arrow_up.png";
import ArrowRight from "../../images/arrow_right.png";
import TrainingSuccessful from "../../images/training-successful.png";
import NotRecognized from "../../images/exercise-not-recognized.png";
import NextExercise from "../../images/next-exercise.png";
import SessionComplete from "../../images/session-complete.png";
import {InitDetectionScript} from "../../js/detection";

export default class Sessions extends React.Component {

    state = {
        currentExerciseId: null,
        currentSession: 0,
    };

    isTraining = false;

    componentDidMount() {
        // Set the current exercise
        this.props.nextExercise();
    }

    componentDidUpdate() {
        let self = this;

        // Check the session
        if(this.props.currentExercise && this.props.currentExercise.id !== this.state.currentExerciseId){
            this.setState({
                currentExerciseId: this.props.currentExercise.id,
                currentSession: this.state.currentSession + 1,
            },(e) => {
                console.log('AFTER UPDATE', e);
                // self.props.updateSession(this.state.currentSession);
            });
        }

        // Ensure that the exercise animation and detection is only triggered once
        if(this.props.currentPart.name === 'training' && !this.isTraining){
            this.isTraining = true;

            // Fire the detection script
            InitDetectionScript(this.props.currentExercise, this.props.currentExercise.exercise_category, (current, results, success) => {
                this.props.captureScreenShots(success);
            });

            // Add the transition class to the loading circle after a small delay so that it doesn't reverse
            setTimeout(() => {
                this.circle.classList.add('loader-transition');
            }, 100);
        }

        // Reset the attributes after the exercise has finished set go to the next exercise
        if(this.props.currentPart.name === 'training-complete' && this.isTraining){
            this.isTraining = false;
        }

        // Run the next exercise
        if(this.props.currentPart.name === 'continue'){
            this.props.nextExercise();
        }
    }

    /**
     * Returns the content for the current part
     *
     * @returns {*}
     */
    getPart(){
        let output = '';

        // Update the module content according to the set timing
        switch(this.props.currentPart.name){
            case 'time-to-train':
                output = (
                    <div className="train-time">
                        <h2 className="time-to">Time to Train</h2>
                        <p className="repetition">Complete <br/>Slow Repetitions <br/>of Each Exercise</p>
                    </div>
                );
                break;
            case 'current-exercise':
                output = (
                    <>
                        <div className="timer">
                            {this.getCircle()}
                            <div className="timer-text">
                                <span className="session">{this.getSessionOrdinal()} Session:</span>
                                <h2 className="step2-heading">{this.props.currentExercise.exercise_name}</h2>
                            </div>
                        </div>
                        {this.getDirection()}
                    </>
                );
                break;
            case 'get-ready':
                output = (
                    <>
                        <div className="timer">
                            {this.getCircle()}
                            <div className="timer-text">
                                <h2 className="step4-heading">{this.props.getReadyTimer(3)}</h2>
                            </div>
                        </div>
                        {this.getDirection()}
                    </>
                );
                break;
            case 'training':
            case 'training-complete':
                output = this.getLoader();
                break;
            case 'finish-session':
                output = (
                    <>
                        <div className="timer">
                            {this.getCircle()}
                            <div className="timer-text">
                                {(this.props.exerciseDetected) ? <h2 className="step2-heading"><img src={TrainingSuccessful} alt="Training Successful"/></h2> : <h2 className="step2-heading"><img src={NotRecognized} alt="Exercise Not Recognized"/></h2> }

                            </div>
                        </div>
                        {this.getDirection()}
                    </>
                );
                break;
            case 'next-session':
            case 'continue':
                output = (
                    <>
                        <div className="timer">
                            {this.getCircle()}
                            <div className="timer-text">
                                {(this.props.upcomingExercise()) ?
                                    <>
                                        <h2 className="step2-heading"><img src={NextExercise} alt="Move to Next Exercise"/></h2>
                                    </> : <>
                                        <h2 className="step2-heading"><img src={SessionComplete} alt="Session Complete"/></h2>
                                    </>
                                }
                            </div>
                        </div>
                        {this.getDirection()}
                    </>
                );
                break;
        }

        return output;
    }

    /**
     * Outputs the exercise footer content with circle loader
     * @returns {*}
     */
    getLoader() {
        return (
            <div className="loader-wrapper">
                <div className="loader" data-html2canvas-ignore="true">
                    <svg viewBox="0 0 64 64">
                        <circle className="loader-value" r="25%" cx="50%" cy="50%" />
                        <circle className="loader-circle" r="25%" cx="50%" cy="50%" style={{strokeDasharray: this.animateLoader(101) + ' 100'}} ref={ref => (this.circle = ref)} />
                    </svg>
                </div>
                <div>
                    <div className="session">SESSION {this.state.currentSession}</div>
                    <div className="title">{this.props.currentExercise.exercise_name}</div>
                </div>
            </div>
        );
    }

    /**
     * Outputs the exercise footer content and direction details
     * @returns {*}
     */
    getDirection() {
      return (
          <div className="loader-wrapper">
              <div>
                  {this.props.currentPart.name === 'current-exercise' && (
                      (this.props.currentExercise.straightOrientation) ?
                          <>
                              <div className="face-arrow"><img src={ArrowUp} alt="Arrow Up"/></div>
                              <div className="title">Face the Camera</div>
                          </> : <>
                              <div className="face-arrow"><img src={ArrowRight} alt="Arrow Right"/></div>
                              <div className="title">Face Your Right</div>
                          </>
                  )}
                  {this.props.currentPart.name === 'get-ready' && (
                      <div>
                          <div className="face-arrow">
                              {(this.props.currentExercise.straightOrientation) ? <img src={ArrowUp} alt="Arrow Up"/> : <img src={ArrowRight} alt="Arrow Right"/>}
                          </div>
                          <div className="session">SESSION {this.state.currentSession}</div>
                          <div className="title">{this.props.currentExercise.exercise_name}</div>
                      </div>
                  )}
              </div>
          </div>
      );
    }


    /**
     *  Outputs the large circle
     * @returns {*}
     */
    getCircle() {
        return (
            <svg viewBox="0 0 64 64" className="landScape-hide">
                <circle className="timer-circle" r="49%" cx="50%" cy="50%" style={{strokeDasharray: 197}} />
            </svg>
        );
    }

    /**
     * Determines if the circle loader should animate
     *
     * @param max (integer) - The max value to calculate the loader percentage against
     * @returns {*}
     */
    animateLoader(max){
        let output = max;

        // Check if current part should use the animated loader loader circle
        if(this.props.currentPart.name === 'training'){
            output = this.props.getLoaderValue(max);
        }

        return output;
    }

    getSessionOrdinal () {
        let output = '';

        switch(this.state.currentSession){
            case 1:
                output = 'First';
                break;
            case 2:
                output = 'Second';
                break;
            case 3:
                output = 'Third';
                break;
        }
        return output;
    }

    render (){

        // Only render if the current exercise is set
        return (this.props.currentExercise) && (
            this.getPart()
        )
    }
}