import * as posenet from "@tensorflow-models/posenet"
import { queueAnimation } from "./sceneManager"
import { findMostSimilarMatch, vptree, warmUpTree } from "./posenetUtils.js"
import { getData, poseTags, warmUpExercises } from "../dataset/data.js"
import {playDetectionSuccess, playDetectionFailure, playExerciseComplete} from "./audioManager"

const _ = require("lodash")

var net
var poseData = []
var warmUpPoseData = []
var poseDetectionTime = 8
var idleTime = 5
var minCount = 0
var shouldCapture = false
var shouldCaptureWarmUp = false
var warmUpDetectionTime = 8
var minDistance = 100
var allowedTags = []
var currentCategory = ""
var currentExercise = null
var mediaStream
var registeredCallBack
var isDetecting = false
var isDetectingWarmUp = false
var isExercise = false
var minPosenetScore = 0.005;

function detect(video) {
  detectPoseInRealTime(video)
}

async function DetectWarmUpRealTime(video) {
  async function poseDetectionFrame() {
    if (net) {
      const pose = await net.estimatePoses(video, {
        decodingMethod: "single-person"
      })
      var poses = []
      poses = poses.concat(pose)
      if (shouldCaptureWarmUp) {
        if(pose[0].score > minPosenetScore){
          warmUpPoseData.push(poses)
        }
        requestAnimationFrame(poseDetectionFrame)
      }
    } else {
      net = await posenet.load({
        architecture: "MobileNetV1",
        multiplier: 0.5
      })
      if (net) {
        detectPoseInRealTime(video)
      }
    }
  }
  await poseDetectionFrame()
}

async function detectPoseInRealTime(video) {
  async function poseDetectionFrame() {
    if (net) {
      const pose = await net.estimatePoses(video, {
        decodingMethod: "single-person"
      })
      var poses = []
      poses = poses.concat(pose)
      if (shouldCapture) {
        if(pose[0].score > minPosenetScore){
          poseData.push(poses)
        }
        requestAnimationFrame(poseDetectionFrame)
      }
    } else {
      net = await posenet.load({
        architecture: "MobileNetV1",
        multiplier: 0.5
      })
      if (net) {
        detectPoseInRealTime(video)
      }
    }
  }
  await poseDetectionFrame()
}

function capturePose(results, video) {
  poseData = []
  shouldCapture = true
  detect(video)
  setTimeout(function() {
    shouldCapture = false
    classify(poseData, results, video)
  }, poseDetectionTime * 1000)
}

function captureWarmUpPoses(video) {
  warmUpPoseData = []
  shouldCaptureWarmUp = true
  DetectWarmUpRealTime(video)
  setTimeout(function() {
    shouldCaptureWarmUp = false
    classifyWarmUp(warmUpPoseData)
  }, warmUpDetectionTime * 1000)
}

function countDown(results, video) {
  capturePose(results, video)
}

function classifyWarmUp(data) {
  isDetectingWarmUp = false
  var matchData = []
  var resObj = new Object()

  var lastTags = []
  var currentTags = []
  for (var i in data) {
    var matches = findMostSimilarMatch(warmUpTree, data[i][0])
    matchData.push(matches)
    currentTags = []
    for (var j in matches.matches) {
      if (matches.matches[j].d < minDistance) {
        var tag = poseTags[matches.matches[j].i]
        if (!resObj[tag]) {
          //first time we see this tag
          resObj[tag] = new Object()
          resObj[tag].distScore = matches.matches[j].d
          resObj[tag].count = 1
          resObj[tag].sequential = 1
          resObj[tag].maxSeq = 1
          resObj[tag].tag = tag
          currentTags.push(tag)
        } else {
          //tag has already been seen

          resObj[tag].distScore += matches.matches[j].d
          resObj[tag].count++
          //if(i > 0){
          if (lastTags.indexOf(tag) != -1) {
            //tag is in lastTags
            if (currentTags.indexOf(tag) == -1) {
              //but not in current tags
              resObj[tag].sequential++
              if (resObj[tag].sequential > resObj[tag].maxSeq) {
                resObj[tag].maxSeq = resObj[tag].sequential
              }
            }
          } else {
            resObj[tag].sequential = 1
          }
          currentTags.push(tag)
          //}
        }

        if (lastTags.indexOf == -1) {
          //save the last tag if we haven't alreay;

          lastTags.push(tag)
          //console.log(lastTags);
        }
      }
      //console.log(currentTags);
      lastTags = currentTags
    }
  }

  console.log("IsWarmUp?: ", IsWarmUp(resObj))
}

function classify(data, results, video) {
  console.log(currentExercise)
  results = document.getElementById("results")
  isDetecting = false
  var matchData = []
  var resObj = new Object()

  var lastTags = []
  var currentTags = []
  for (var i in data) {
    var matches = findMostSimilarMatch(vptree, data[i][0])
    matchData.push(matches)
    currentTags = []
    for (var j in matches.matches) {
      if (matches.matches[j].d < minDistance) {
        var tag = poseTags[matches.matches[j].i]
        if (!resObj[tag]) {
          //first time we see this tag
          resObj[tag] = new Object()
          resObj[tag].distScore = matches.matches[j].d
          resObj[tag].count = 1
          resObj[tag].sequential = 1
          resObj[tag].maxSeq = 1
          resObj[tag].tag = tag
          currentTags.push(tag)
        } else {
          //tag has already been seen

          resObj[tag].distScore += matches.matches[j].d
          resObj[tag].count++
          //if(i > 0){
          if (lastTags.indexOf(tag) != -1) {
            //tag is in lastTags
            if (currentTags.indexOf(tag) == -1) {
              //but not in current tags
              resObj[tag].sequential++
              if (resObj[tag].sequential > resObj[tag].maxSeq) {
                resObj[tag].maxSeq = resObj[tag].sequential
              }
            }
          } else {
            resObj[tag].sequential = 1
          }
          currentTags.push(tag)
          //}
        }

        if (lastTags.indexOf == -1) {
          //save the last tag if we haven't alreay;

          lastTags.push(tag)
          //console.log(lastTags);
        }
      }
      //console.log(currentTags);
      lastTags = currentTags
    }
  }

  console.log(currentExercise.category)

  if (IsExercise(resObj)) {
    isExercise = true;
    playDetectionSuccess(
      currentExercise.exercise_category,
      currentExercise.currentLevel
    )
    queueAnimation(
      currentExercise.animName + currentExercise.currentLevel, true, false, 0, function() {
        playExerciseComplete(currentExercise.exercise_category)
      }
    )
    queueAnimation("MainIdle")
  } else {
    isExercise = false;
    playDetectionFailure(currentExercise.exercise_category)
    queueAnimation("Shrug", true)
    queueAnimation("MainIdle")
    queueAnimation("SecondaryIdle")
    queueAnimation("MainIdle")
  }

  //CreateTable(resObj, matchData, results, video)
  console.log("IsExercise(resObj) : ", IsExercise(resObj))
  console.log("resObj, matchData,: ", resObj, matchData)
}

function CreateTable(sourceObj, matchData, results, video) {
  console.log("create table: sourceObj : ", sourceObj)
  var innerHTML =
    currentExercise.exercise_name +
    "<br><table><tr><th>Exercise</th><th>Total Distance</th><th>Tag Count</th><th>Avg Distance</th><th>Largest Sequence</tr>"
  for (var i in sourceObj) {
    //  console.log(i);
    if (sourceObj[i].count >= minCount) {
      var tableRow = `<tr><td>${i}</td><td>${sourceObj[i].distScore}</td><td>${
        sourceObj[i].count
      }</td><td>${sourceObj[i].distScore / sourceObj[i].count}</td><td>${
        sourceObj[i].maxSeq
      }</td></tr>`
      innerHTML += tableRow
    }
  }

  if (results) {
    // results.innerHTML += ("</table><br>" + innerHTML)
    console.log("fin")
  }

  var _isExerise = document.getElementById("isExercise")
  if (_isExerise && currentExercise.exercise_name == "") {
    _isExerise.innerHTML = "No Exercise Selected"
  } else if (_isExerise) {
    _isExerise.innerHTML = IsExercise(sourceObj) ? "Pass!" : "Fail!"
  }
}

function exerciseDetected() {
  return isExercise;
}

function IsExercise(results) {
  console.log("results: ", results)
  console.log("currentExercise: ", currentExercise)

  var name = currentExercise["exercise_name"]
  var alt = currentExercise["alternative"]
  var keyword = currentExercise["keyword"]
  var keyword2 = currentExercise["keyword2"]
  var objArray = []

  for (var i in results) {
    objArray.push(results[i])
  }

  var topCounts = []
  var topDists = []
  var topSeqs = []

  topCounts = _.sortBy(objArray, ["count"]).reverse()
  topDists = _.sortBy(objArray, [
    function(o) {
      return o.distScore / o.count
    }
  ])
  topSeqs = _.sortBy(objArray, ["maxSeq"]).reverse()

  for (i = 0; i < topCounts.length; i++) {
    if (topCounts[i].tag == name) {
      registeredCallBack(topCounts[i].tag, results, true)
      return true
    } else if (alt != "") {
      if (topCounts[i].tag == alt) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    } else if (keyword != "") {
      if (topCounts[i].tag.indexOf(keyword) != -1) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    } else if (keyword2 != "") {
      if (topCounts[i].tag.indexOf(keyword2) != -1) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    }
  }

  for (i = 0; i < topDists.length; i++) {
    if (topDists[i].tag == name) {
      registeredCallBack(topCounts[i].tag, results, true)
      return true
    } else if (alt != "") {
      if (topDists[i].tag == alt) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    } else if (keyword != "") {
      if (topDists[i].tag.indexOf(keyword) != -1) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    } else if (keyword2 != "") {
      if (topDists[i].tag.indexOf(keyword2) != -1) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    }
  }

  for (i = 0; i < topSeqs.length; i++) {
    if (topSeqs[i].tag == name) {
      registeredCallBack(topCounts[i].tag, results, true)
      return true
    } else if (alt != "") {
      if (topSeqs[i].tag == alt) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    } else if (keyword != "") {
      if (topSeqs[i].tag.indexOf(keyword) != -1) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    } else if (keyword2 != "") {
      if (topSeqs[i].tag.indexOf(keyword2) != -1) {
        registeredCallBack(topCounts[i].tag, results, true)
        return true
      }
    }
  }
  registeredCallBack(currentExercise, results, false)

  return false
}

async function InitDetectionScript(exercise, category, callback) {
  console.log("Init Detection Script")
  const exerciseName = exercise.exercise_name
  if (
    currentExercise == null ||
    exerciseName !== currentExercise.exercise_name
  ) {
    console.log(
      "exerciseName !== currentExercise: ",
      exerciseName,
      "currentExercise: ",
      currentExercise,
      " ,"
    )
    setExercise(exercise)
    StartDetection()
    setCategory(category, exerciseName)
  }

  poseData = []
  allowedTags = []
  registeredCallBack = callback
  if (!net) {
    net = await posenet.load({
      architecture: "MobileNetV1",
      multiplier: 0.5
    })
  }
}
function IsWarmUp(results) {
  var objArray = []

  for (var i in results) {
    objArray.push(results[i])
  }

  var topCounts = []
  var topDists = []
  var topSeqs = []

  topCounts = _.sortBy(objArray, ["count"]).reverse()
  topDists = _.sortBy(objArray, [
    function(o) {
      return o.distScore / o.count
    }
  ])
  topSeqs = _.sortBy(objArray, ["maxSeq"]).reverse()
  for (var j = 0; j < warmUpExercises.length; j++) {
    for (i = 0; i < topCounts.length; i++) {
      if (topCounts[i].tag == warmUpExercises[j]) {
        return true
      }
    }

    for (i = 0; i < topDists.length; i++) {
      if (topDists[i].tag == warmUpExercises[j]) {
        return true
      }
    }

    for (i = 0; i < topSeqs.length; i++) {
      if (topDists[i].tag == warmUpExercises[j]) {
        return true
      }
    }
  }

  return false
}

function json(response) {
  return response.json()
}

function setCategory(c, exerciseName) {
  if (currentCategory != c) {
    console.log("Set Category")
    allowedTags = []
    currentCategory = c
    GetExercises(exerciseName)
  }
}

function setExercise(e) {
  console.log("SET EXERCISE: ", e)
  currentExercise = e
}

async function GetExercises(exerciseName) {
  console.log(exerciseName)
  console.log(currentCategory)
  fetch("https://pumaweb.currentstudios.io:8080/getExercisesByCategory", {
    method: "post",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json"
    },
    body: JSON.stringify({
      category: currentCategory
    })
  })
    .then(json)
    .then(function(data) {
      console.log("exerciseName: ", exerciseName)
      allowedTags = []
      for (var i in data) {
        allowedTags.push(data[i]["exercise_name"])
      }
      getData()
    })
    .catch(function(error) {
      console.log("Request failed", error)
    })
}

function StartDetection(exercise) {
  if (exercise && exercise.exercise_name !== currentExercise.exercise_name) {
    setExercise(exercise)
  }
  if (exercise && exercise.category_name !== currentCategory) {
    setCategory(exercise.category_name, exercise.exercise_name)
  }
  console.log("isDececting: ", isDetecting)
  if (!isDetecting) {
    isDetecting = true
    var video = document.getElementById("video")
    var results = document.getElementById("results")
    capturePose(results, video)
    queueAnimation("MainIdle", true)
    queueAnimation("StudyIdleAlt")
  }
}

function StartWarmUpDetection() {
  if (!isDetectingWarmUp) {
    isDetectingWarmUp = true
    var video = document.getElementById("video")
    console.log("-- Started Warm Up detection")
    DetectWarmUp(video)
  }
}

function DetectWarmUp(video) {
  captureWarmUpPoses(video)
}

function processingBeforeDetection(exercise) {
  if (exercise && exercise.exercise_name !== currentExercise.exercise_name) {
    setExercise(exercise)
  }
  if (exercise && exercise.category_name !== currentCategory) {
    setCategory(exercise.category_name, exercise.exercise_name)
  }
}

function stopVideo() {
  if (mediaStream) mediaStream.getTracks()[0].stop()
}

// init();
export {
  InitDetectionScript,
  allowedTags,
  stopVideo,
  StartDetection,
  setExercise,
  setCategory,
  StartWarmUpDetection,
  exerciseDetected
}
