import React, { Component } from "react"
import {Footer,
  Header,
  Nav,
  SelectBackground,
  SelectShoe} from "./../components"
import { connect } from "react-redux"

class Train extends Component {
  render() {
    const { color, shoeType, chooseColor } = this.props

    return (
      <>
        <Header />
        <SelectBackground color={color} chooseColor={clr => chooseColor(clr)} />
        <SelectShoe color={color} shoeType={shoeType} chooseColor={clr => {}} />
        <Nav props={this.props} />
        <section className={"home " + `${color}`}>
          <div
            className={"banner" + `${this.props.isTrainActive ? " fade" : ""}`}
          >
            <h1 className="banner-heading">ASTRO</h1>
            <h2 className="banner-subheading">
              THE HYBRID ATHLETE FOREVER EVOLVING
            </h2>
            <p className="banner-para">
              Help Puma create the ultimate hybrid athlete with an AI character
              that’s made by everything you do.
            </p>
          </div>
        </section>
        <Footer color={color} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive
  }
}

export default connect(mapStateToProps)(Train)
