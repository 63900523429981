import React, { Component } from "react"
import { Link } from "react-router-dom"
import {playIntro, showScene} from "../js/sceneManager";
import {setCameraPermission} from "../utils/helper";

export default class PopUp extends Component {
  state = {
    isChecked: false,
    hasCamera: true,
  };

  componentDidMount() {
    this.checkCamera();
  }

  checkCamera() {
    console.log('Check for camera support');
    var that = this;

    navigator.mediaDevices.getUserMedia({video: true})
        .then(function(stream) {
          that.setState({hasCamera: true});
        })
        .catch(function(err) {
          console.error('NO CAMERA SUPPORT: ' + err.message);
          that.setState({hasCamera: false});
        });
  }

  grantAccessHandler(e){
      // Do nothing if the user has not accepted the terms
      if(this.state.isChecked){
          // Save the permission
          setCameraPermission();
      } else {
          e.preventDefault();
      }
  }

  render() {
    const { isChecked, hasCamera } = this.state
    return (
      <div className="pop-up">
        <div className="cam-access">
            {(hasCamera)?
            <div><p className="access-para">This experience requires access to your device’s camera feed to continue.</p></div> :
            <div><p className="access-para"><strong>To train Astro camera access is required.</strong></p><p className="access-sub-para">Try granting access to use the device’s camera, visit another browser or use a laptop with a camera.</p></div>}

            {hasCamera && (
                <div>
                    <input
                        className="checkbox"
                        id="check"
                        type="checkbox"
                        checked={isChecked}
                        onChange={() =>
                            this.setState({
                                isChecked: !this.state.isChecked
                            })
                        }
                    />
                    <label htmlFor="check" className="accept-policy">
                        I accept the{" "}
                        <a href="https://au.puma.com/terms-of-use" target="_blank">
                            Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a target="_blank" href="https://au.puma.com/privacy-policy">
                            Privacy Policy
                        </a>
                        .
                    </label>
                </div>
            )}
          <div className="pop-up-btn">
            <Link
              onClick={() => {
                // Play the intro after navigating back to the start page
                if (window.location.pathname.length > 1) {
                  playIntro()
                } else {
                  showScene()
                }
              }}
              className="astro-btn cancel-btn"
              to={'/'}
            >
              Cancel
            </Link>
            {hasCamera  && (
                <Link
                onClick={ e => { this.grantAccessHandler(e) }}
                className={"astro-btn " + `${isChecked ? "" : "disabled-btn"}`}
                to={{
                    pathname: "/start-training",
                    state: {
                        ...this.props.selectedExercises
                    }
                }}>
                Grant Access
            </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}
