import React, { Component } from "react"
import {Footer,
  Header,
  Nav,
  SelectBackground,
  WatchVideo} from "./../components"
import Slider from "react-slick"
import { connect } from "react-redux"
import { hideScene } from "../js/sceneManager"

class Watch extends Component {
  state = {
    videoSrc: null,
    mediaRecorder: null,
    currentTime: "",
    videoBlob: null,
    isStopped: true,
    lastTime: "",
    expandVideo: false,
    loader: false,
    play: false
  };

  handelPlay = () => {
    this.setState({
      expandVideo: !this.state.expandVideo
    })
  };
  handelPlayPause = () => {
    const video = document.getElementById("video")
    if (!video.paused) {
      video.pause()
      this.setState({
        play: true
      })
    } else {
      video.play()
      this.setState({
        play: false
      })
    }
  };

  componentDidMount() {
    hideScene()
  }

  render() {
    const { expandVideo } = this.state
    var settings = {
      className: "center variable-width",
      infinite: true,
      arrow: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      variableWidth: true,
      cssEase: "linear",
      centerPadding: "60px",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false
          }
        },
        {
          breakpoint: 640,
          settings: {
            arrows:false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false
          }
        }
      ]
    }
    const { color, chooseColor } = this.props

    return (
      <>
        <Header />
        <Nav props={this.props} />
        <section className={"watch " + `${color}`}>
          <Slider
            {...settings}
            className={this.props.isTrainActive ? " fade" : ""}
          >
            {/*<WatchVideo  url={"https://player.vimeo.com/video/348977144"} title={"Coming Soon"} info={"Puma are creating Astro the ultimate hybrid athlete. Powered by AI and trained by you, Astro is forever evolving. Session by session, skill by skill, until every movement is mastered."} expandVideo={expandVideo}/>*/}
            <WatchVideo
              url={"https://player.vimeo.com/video/348940362"}
              title={"Sydney Stack"}
              info={
                "AFL young gun Sydney Stack continues to evolve Astro the AI athlete while testing the new PUMA Hybrid Astro shoe."
              }
              expandVideo={expandVideo}
            />
            <WatchVideo
              url={"https://player.vimeo.com/video/348939996"}
              title={"Scott Pendlebury and Jeremy Howe"}
              info={
                "Smooth moving Scott Pendlebury and high-flying Jeremy Howe continue to evolve Astro the AI athlete while testing the new PUMA Hybrid Astro shoe."
              }
              expandVideo={expandVideo}
            />
            <WatchVideo
              url={"https://player.vimeo.com/video/348939761"}
              title={"Jess and Sarah Hosking"}
              info={
                "Double threat Jess and Sarah Hosking continue to evolve Astro the AI athlete while testing the new PUMA Hybrid Astro shoe."
              }
              expandVideo={expandVideo}
            />
            <WatchVideo
              url={"https://player.vimeo.com/video/348939455"}
              title={"Renae Ingles"}
              info={
                "Netball fan-favourite Renae Ingles continues to evolve Astro the AI athlete while testing the new PUMA Hybrid Astro shoe."
              }
              expandVideo={expandVideo}
            />
            <WatchVideo
              url={"https://player.vimeo.com/video/348939082"}
              title={"Katie Brennan"}
              info={
                "AFLW star Katie Brennan continues to evolve Astro the AI athlete while testing the new PUMA Hybrid Astro shoe."
              }
              expandVideo={expandVideo}
            />
            <WatchVideo
              url={"https://player.vimeo.com/video/348938919"}
              title={"Jack Ziebell"}
              info={
                "AFL hard nut Jack Ziebell continues to evolve Astro the AI athlete while testing the new PUMA Hybrid Astro shoe."
              }
              expandVideo={expandVideo}
            />
          </Slider>
          <SelectBackground
            color={color}
            chooseColor={clr => chooseColor(clr)}
          />
        </section>
        <Footer color={color} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive
  }
}

export default connect(mapStateToProps)(Watch)
