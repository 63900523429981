import React, {Component} from "react"
import {Header, Nav} from "./../components"
import {Start, WarmUp, Sessions, Finish} from "./../components/training"
import VideoCam from "./VideoCam"
import {captureScene, playStartTraining} from "../js/sceneManager";
import html2canvas from "html2canvas";
import {isWarmedUp} from "../utils/helper";
import astroLogo from "./../images/astro-ByPuma.png";
import pumaLogo from "./../images/puma-logo.png";

let timerInterval;

export default class StartTraining extends Component {
    /**
     * Defines the timings and other attributes for each step
     */
    trainingSteps = [
        {
            name: 'start',
            parts:[
                {
                    name: 'intro',
                    duration: 2,},
                {
                    name: 'get-aligned',
                    duration: 6,},
                {
                    name: 'astro-aligned',
                    duration: 2,},
            ],
        },
        {
            name: 'warm-up',
            parts: [
                {
                    name: 'follow-astro',
                    duration: 2,},
                {
                    name: 'follow-astro-countdown',
                    duration: 20,},
                {
                    name: 'follow-astro-countdown-complete', // Adds a small delay so the loading circle can complete it's animation
                    duration: 1,},
                {
                    name: 'warm-up-complete',
                    duration: 2,},
            ],
        },
        {
            name: 'sessions',
            parts: [
                {
                    name: 'time-to-train',
                    duration: 2,},
                {
                    name: 'current-exercise',
                    duration: 2,},
                {
                    name: 'get-ready',
                    duration: 3,},
                {
                    name: 'training',
                    duration: 20,},
                {
                    name: 'training-complete',
                    duration: 2,},
                {
                    name: 'finish-session',
                    duration: 2,},
                {
                    name: 'next-session',
                    duration: 2,},
                {
                    name: 'continue',
                    duration: 1,},
            ],
        },
        {
            name: 'finish',
            parts: [
                {
                    name: 'thank-you',
                    duration: 2,},
                {
                    name: 'share-results',
                    duration: 2,},
            ],
        },
    ];

    trainingStarted = false;
    currentPartTimer = 0;
    isCapturing = false;
    exerciseDetected = false;
    totalScreenShots = 4;
    screenShotInterval;
    activeExercises = (typeof this.props.location.state.activeExercises !== 'undefined') ? this.props.location.state.activeExercises : [];

    state = {
        timer: 0,
        currentStep : this.trainingSteps[0],
        currentPart: this.trainingSteps[0].parts[0],
        currentExercise: null,
        currentSession: 1,
        screenShots: [],
    };

    componentDidMount() {
        console.log('Begin step: ' + this.state.currentStep.name);
        console.log('Begin part: ' + this.state.currentPart.name);

        // Check if the warm up has been completed already
        this.checkWarmUp();

        // Play the initial training idle animation sequence
        playStartTraining();
    }

    componentDidUpdate() {
        // Take the exercise screen shots
        if(this.state.currentStep.name === 'sessions' && this.state.currentPart.name === 'training'){

            // Check for capturing state requirements
            if(this.exerciseDetected && !this.isCapturing && this.totalScreenShots){
                let self = this;
                this.isCapturing = true;

                // Capture the screen shot every 1.75s
                this.screenShotInterval = setInterval(() => {
                    if(self.totalScreenShots && self.isCapturing){
                        console.log('Capture screen shot :' + self.totalScreenShots);
                        self.getScreenShotFrame();
                        self.totalScreenShots--;
                    }
                }, 1750);
            } else if(!this.totalScreenShots) {
                clearInterval(this.screenShotInterval);
            }
        }

        // Reset capturing state
        if(this.state.currentStep.name === 'sessions' && this.state.currentPart.name === 'training-complete'){
            this.isCapturing = false;
            this.totalScreenShots = 4;
        }

        // Reset the exercise detected state
        if(this.state.currentStep.name === 'sessions' && this.state.currentPart.name === 'next-session'){
            this.exerciseDetected = false;
        }

        // End the timer and go to the share results page
        if(this.state.currentStep.name === 'finish' && this.state.currentPart.name === 'share-results'){
            this.trainingStarted = false;
            clearInterval(this.timerInterval);
           this.gotoShare();
        }

        // Update the current part timer
        if(Number.isInteger(this.currentPartTimer)){
            this.updatePartTimer();
        } else {
            this.nextPart(0);
        }
    }

    /**
     * Routes the user to the training complete page so they can share their results
     */
    gotoShare() {
        this.props.history.push("/training-complete", {
            exercises: this.activeExercises.map(e => e.exercise_name),
            category: this.activeExercises[0] && this.activeExercises[0].exercise_category,
            exercisesShots: this.state.screenShots,
            // exercisesResults: this.state.exercisesResults
        })
    }

    /**
     * Returns the component markup for the current training step
     *
     * @returns {*}
     */
    getCurrentStep() {
        let output = '';

        switch(this.state.currentStep.name){
            case 'start':
                output = (
                    <Start
                        currentPart={this.state.currentPart}
                        startTimer={ () => { this.startTimer() } } />
                );
                break;
            case 'warm-up':
                output = (
                    <WarmUp
                        currentPart={this.state.currentPart}
                        nextStep={ () => { this.nextStep() } }
                        getLoaderValue={ max => { return this.getLoaderValue(max) } }/>
                    );
                break;
            case 'sessions':
                output = (
                    <Sessions
                        currentPart={this.state.currentPart}
                        currentExercise={this.state.currentExercise}
                        updateSession={ () => { this.updateSession() }}
                        exerciseDetected={this.exerciseDetected}
                        nextStep={ () => { this.nextStep() } }
                        nextExercise={ () => { this.nextExercise() } }
                        upcomingExercise={ () => { return this.upcomingExercise() } }
                        getLoaderValue={ max => { return this.getLoaderValue(max) } }
                        getReadyTimer={ count => { return this.getReadyTimer(count) } }
                        captureScreenShots={ (success) => { this.captureScreenShots(success) } }
                    />
                );
                break;
            case 'finish':
                output = (
                    <Finish/>
                );
                break;
        }

        return output;
    }

    /**
     * Starts the training process
     */
    startTimer(){
        console.log('Start the training process');

        // Check if the user initiated the training
        if(!this.trainingStarted){
            this.trainingStarted = true;

            // Immediately go to the next part
            this.nextPart();

            // Update the timer state every 1 second
            timerInterval = setInterval(() => {
                if(this.trainingStarted){
                    this.setState({
                        timer: this.state.timer + 1
                    });
                }
            }, 1000);
        }
    }

    /**
     * Counts down the duration of each part and initiates the next part when the timer expires
     */
    updatePartTimer () {
        if (this.currentPartTimer >= -1) {
            // Decrease the current part timer state by increments of 1 second
            this.currentPartTimer = this.currentPartTimer - 1;

            // Go to the next part if the part duration has expired
            if (this.currentPartTimer <= -1) {
                this.nextPart();
            }
        }
    }

    /**
     * Iterates to the next step in the training session
     *
     * @param target (integer) - Go to a specific step
     */
    nextStep(target){

        // Get the index of the current step within the current step
        let index = this.trainingSteps.findIndex((step) => {return step.name === this.state.currentStep.name}),
            nextStep,
            nextPart;

        // Check if the requested step exists
        if(target && target > this.trainingSteps.length - 1){
            console.error('Requested step not found');
            target = false;
        }

        // Check if the iteration has reached the limit
        if(index + 1 > this.trainingSteps.length - 1){
            console.log('All steps have been completed, terminate the timer');
            clearInterval(timerInterval);
            return;
        }

        // Set the next step and update the current part
        nextStep = this.trainingSteps[target ? target : index + 1];

        this.setState({
            currentStep: nextStep,
        });

        this.currentPartTimer = null;

        console.log('Begin step: ' + nextStep.name);
    }

    /**
     * Iterates to the next part within the current step
     *
     * @param target (integer) - Go to a specific part
     */
    nextPart(target){
        target = (typeof target !== 'undefined') ? target : false;

        // Get the index of the current part within the current step
        let index = this.state.currentStep.parts.findIndex((part) => {return part.name === this.state.currentPart.name}),
            nextPart;

        // Check if the requested part exists within the current step
        if(!Number.isInteger(target) && target > this.state.currentStep.parts.length - 1){
            console.error('Requested part not found');
            target = false;
        }

        // Check if the iteration has reached the limit
        if(index + 1 > this.state.currentStep.parts.length - 1){
            console.log('All parts have been completed, go to the next step');
            this.nextStep();
            return;
        }

        // Set the next part
        nextPart = this.state.currentStep.parts[Number.isInteger(target) ? target : index + 1];
        this.setState({
            currentPart: nextPart,
        });

        this.currentPartTimer = nextPart.duration;

        console.log('Begin part: ' + nextPart.name);
    }

    /**
     * Iterates to the next exercise within the current session
     */
    nextExercise(){
        let index = (this.state.currentExercise) ? this.activeExercises.findIndex((e) => {return e.id === this.state.currentExercise.id}) : 0,
            nextExercise;

        // Check if the iteration has reached the limit
        if(this.state.currentExercise && index + 1 > this.activeExercises.length - 1){
            console.log('All exercises have been completed');
            this.nextStep();
            return;
        }

        // Check for exercises
        if(this.activeExercises.length){
            nextExercise = this.activeExercises[this.state.currentExercise ? index + 1 : 0];

            this.setState({
                currentExercise: (!this.state.currentExercise) ? this.activeExercises[0] : nextExercise,
                currentPart: this.state.currentStep.parts[0], // Reset the current step back to part 1 (current-exercise)
            });
        } else {
            console.error('No exercises found');
        }

        console.log('Begin exercise: ' + nextExercise.exercise_name);
    }

    /**
     * Checks to see if there is an exercise in queue
     */
    upcomingExercise() {
        let index = this.activeExercises.findIndex((e) => {return e.id === this.state.currentExercise.id});
        return !!(index + 1 <= this.activeExercises.length - 1);
    }

    /**
     * Removes the warm up from the training steps array if the warm up has been completed already
     */
    checkWarmUp () {
        if(isWarmedUp()){
            let index = this.trainingSteps.findIndex((step) => {return step.name === 'warm-up'});
            this.trainingSteps.splice(index, 1);
        }
    }


    /**
     * Calculates the loading percentage
     *
     * @param max (integer) - The max number value to calculate the loader percentage against
     */
    getLoaderValue(max){

        // Ensure that the max argument is provided and is valid
        if(typeof max === 'undefined' && Number.isInteger(max)){
            console.error('Max argument is required and must be an integer');
            return;
        }

        // Check for current part duration and calculate the loader value
        if(this.state.currentPart.duration !== 'undefined'){
            let buffer = (this.state.currentStep.name === 'warm-up') ? this.currentPartTimer + 1 : this.currentPartTimer; // Use a buffer of 1 second on the warm up step to compensate for timing discrepancy
            let perc = (buffer / (this.state.currentPart.duration) * max).toFixed(2);

            return (max - perc);
        }
    }

    /**
     * Iterates itself to updates the timer for the get ready part in the sessions step according to it's duration
     *
     * @param count (integer) - The number to start the countdown from
     * @param newCount (integer) - The count decreased by 1 when iterating itself
     * @returns {number}
     */
    getReadyTimer(count, newCount) {
        newCount = (typeof newCount !== 'undefined') ? newCount : count;
        let increment = Math.ceil(this.state.currentPart.duration / count);

        // Check if the timer is less than the next increment down
        if(this.currentPartTimer <= increment * (newCount - 1)){
            newCount = this.getReadyTimer(count,newCount-1)
        }

        return newCount;
    }

    /**
     * Updates the current session
     */
    updateSession(session){
        this.setState({
            currentSession: session,
        });
    }

    /**
     * Starts the screen shot capture process
     * @param success (boolean) - Has the exercise been recognized successfully
     */
    captureScreenShots(success){
        this.exerciseDetected = success;
    }

    /**
     * Builds the layered screen shot frames that will be converted to the shareable GIF
     */
    getScreenShotFrame() {
        const self = this;
        const layers = document.getElementById('screen-shot-layers');
        const video = document.getElementById('video');
        const exerciseName = self.state.currentExercise.exercise_name.toLowerCase();

        let newScreenShots = self.state.screenShots;

        if (!newScreenShots[exerciseName]) {
            newScreenShots[exerciseName] = [];
        }

        // Stack the GIF frame image layers
        let sceneCapture = document.createElement('div');
        let uiContainer = document.createElement('div');
        let cameraCapture = new Image();
        let cameraCanvas = document.createElement('canvas');
        let cameraCanvasCtx = cameraCanvas.getContext('2d');
        let overlay = document.createElement('div');
        let frame = document.createElement('div');

        // Create the camera screen shot as a greyscale image
        cameraCanvas.width = video.width;
        cameraCanvas.height = video.height;
        cameraCanvasCtx.fillStyle = "white";
        cameraCanvasCtx.fillRect(0, 0, cameraCanvas.width, cameraCanvas.height);
        cameraCanvasCtx.globalCompositeOperation = 'luminosity';
        cameraCanvasCtx.drawImage(video, 0, 0, cameraCanvas.width, cameraCanvas.height);
        cameraCapture.className = 'frame-parts frame-part-camera';
        cameraCapture.src = cameraCanvas.toDataURL();
        overlay.className = 'screen-shot-camera-overlay overlay';
        frame.append(cameraCapture);
        frame.append(overlay);

        // Stack the layered images in the frame container
        frame.id = 'screen-shot-frame-' + layers.childElementCount;
        frame.className = 'screen-shot-frame ' + self.props.color + '-overlay';

        // Create the Astro screen shot if the exercise was detected successfully
        sceneCapture.className = 'frame-parts frame-part-astro';
        sceneCapture.style.backgroundImage = 'url(' + captureScene() + ')';
        frame.append(sceneCapture);

        // Create the frame UI elements
        uiContainer.className = 'frame-parts frame-part-ui';
        uiContainer.innerHTML = '<img src="' + astroLogo + '" alt="Astro By Puma" class="screen-shot-astro-logo" />\n' +
                                '<img src="' + pumaLogo + '" alt="Puma Logo" class="screen-shot-puma-logo"/>\n' +
                                '<div class="screen-shot-footer">\n' +
                                    '<div class="session">SESSION ' + this.state.currentSession + '</div>\n' +
                                    '<div class="title">' + this.state.currentExercise.exercise_name + '</div>\n' +
                                '</div>';
        frame.append(uiContainer);

        // Add the frame to the screen shot container
        layers.append(frame);

        // Take a screen shot of the combined layers
        html2canvas(document.getElementById(frame.id)).then(function (_canvas) {
            newScreenShots[exerciseName].push(_canvas.toDataURL());

            self.setState({
                screenShots: newScreenShots,
            });
        });
    }

    render(){
        const {color, location} = this.props;

        return (
            <>
                <Header/>
                <Nav props={this.props}/>
                <VideoCam/>
                <section className={"start-training " + `${color}` + "-overlay"}>
                    {this.getCurrentStep()}
                    <div className="overlay" data-html2canvas-ignore="true"/>
                </section>
                <div id="screen-shot-layers"/>
            </>
        )
    }
}