import React from "react"
import { Link } from "react-router-dom"
import { hideScene } from "../js/sceneManager"

const TrainAstro = props => (
  <div className="train-astro">
    <img
      className="active-img1"
      src={require("./../images/" + `${props.trainImage2}`)}
      alt={props.trainName}
    />
    <img
      className="active-img2"
      src={require("./../images/" + `${props.trainImage}`)}
      alt={props.trainName}
    />
    <div className="astro-wrapper">
      <span className="astro">Train Astro</span>
      <h3 className="train-name">{props.trainName} Training</h3>
      <Link
        className="train-btn"
        to={"/training/" + props.trainName}
        onClick={() => {
          props.selectCategory();
          hideScene()
        }}
      >
        BEGIN TRAINING
      </Link>
    </div>
  </div>
)

export default TrainAstro
