import React, { Component } from "react"
import { Link } from "react-router-dom"
import {Footer,
  Header,
  MobilePopUp,
  Nav,
  SelectBackground,
  SelectExercise,
  SelectShoe} from "./../components"
import { connect } from "react-redux"
import { setCategory } from "../js/detection.js"
import { hideScene } from "../js/sceneManager"

class Training extends Component {
  state = {
    isPopUp: false,
    activeExercises: []
  };

  makeActive = ex => {
    let { activeExercises } = this.state

    if (activeExercises.includes(ex)) {
      let index = activeExercises.indexOf(ex)
      activeExercises.splice(index, 1)
    } else if (activeExercises.length > 2) {
      activeExercises[0] = ex
    } else {
      activeExercises.push(ex)
    }
    this.setState({
      activeExercises
    })
  };
  componentDidMount() {
    hideScene()
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0)
    const { match } = this.props
    if (match.params.name && nextProps.match.params.name) {
      if (match.params.name !== nextProps.match.params.name) {
        this.setState({
          activeExercises: []
        })
      }
    }
  }

  render() {
    const { makeActive, handelPopUp } = this
    const { activeExercises, isPopUp } = this.state
    const { color, chooseColor } = this.props
    const trainingName = this.props.match.params.name
    const exercises = this.props.categories[trainingName]
    const isDisabled = activeExercises[0] ? false : true
    setCategory(trainingName)
    return (
      <React.Fragment>
        <Header />
        <Nav props={this.props} />
        <section
          className={
            "training " + `${color}` + " " + `${isPopUp ? "blur" : ""}`
          }
        >
          <div
            className={
              "training-header " + `${this.props.isTrainActive ? "fade" : ""}`
            }
          >
            <span className="title">TRAIN ASTRO</span>
            <h2 className="heading">{trainingName} TRAINING</h2>
          </div>
          <div
            className={
              "training-container " +
              `${this.props.isTrainActive ? "fade" : ""}`
            }
          >
            <p className="train-session">
              Select up to 3 exercises to train Astro. <br/>
              Each training session will last 20 seconds.
            </p>
            <div className="tabs">
              <div
                className={
                  "tab " +
                  `${
                    activeExercises[0] && activeExercises[0].exercise_name
                      ? "active"
                      : ""
                  }`
                }
              >
                {activeExercises[0] && activeExercises[0].exercise_name ? (
                  activeExercises[0].exercise_name
                ) : (
                  <span>Add Exercise</span>
                )}
              </div>
              <div
                className={
                  "tab " +
                  `${
                    activeExercises[1] && activeExercises[1].exercise_name
                      ? "active"
                      : ""
                  }`
                }
              >
                {activeExercises[1] && activeExercises[0].exercise_name ? (
                  activeExercises[1].exercise_name
                ) : (
                  <span>Add Exercise</span>
                )}
              </div>
              <div
                className={
                  "tab " +
                  `${
                    activeExercises[2] && activeExercises[2].exercise_name
                      ? "active"
                      : ""
                  }`
                }
              >
                {activeExercises[2] && activeExercises[0].exercise_name ? (
                  activeExercises[2].exercise_name
                ) : (
                  <span>Add Exercise</span>
                )}
              </div>
            </div>
            <div className="squat">
              <SelectExercise
                activeExercises={activeExercises}
                color={color}
                makeActive={makeActive}
                exercisesName={exercises}
              />
            </div>
            <Link
              onClick={e => (isDisabled ? e.preventDefault() : null)}
              to={{
                pathname: trainingName + "/start-train",
                state: {
                  activeExercises: activeExercises
                }
              }}
              className={
                "astro-btn next-btn " + `${isDisabled ? "disabled-btn" : ""}`
              }
            >
              Next
            </Link>

            <Link
              style={{
                opacity: isDisabled ? "0" : "1"
              }}
              to={{
                pathname: trainingName + "/start-train",
                state: {
                  activeExercises: activeExercises
                }
              }}
              className={
                "astro-btn mobo-next-btn" +
                `${isDisabled ? " disabled-btn" : ""}`
              }
            >
              Next
            </Link>
          </div>
        </section>
        {/*<SelectBackground color={color} chooseColor={(clr) => chooseColor(clr)}/>*/}
        {/*<SelectShoe color={color} chooseColor={(clr) => { }}/>*/}
        <Footer color={color} />
        {isPopUp ? (
          <MobilePopUp
            color={color}
            target={{
              pathname: trainingName + "/start-train",
              state: {
                activeExercises: activeExercises
              }
            }}
            handelPopUp={handelPopUp}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    exercises: state.exercises,
    categoryType: state.categoryType,
    isTrainActive: state.isTrainActive
  }
}

export default connect(mapStateToProps)(Training)
