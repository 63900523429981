import tracks from "../audio/puma-web-audio-v2.m4a"
import Debug from "./debug";

let _player,
  nextClip = {
  },
  currentClip = "",
  maxVolume = 0.4,
  muted = false,
  isPlaying = false,
  debug = new Debug(false, false, 'Audio');

/**
 * Initiates the audio functionality by setting our global variables
 *
 * @param player (obj) - The HTML5 audio element
 */
function initAudio(player) {
  _player = player;
  _player.src = tracks;
  _player.volume = maxVolume;

  // Add a click listener on the entire document so that we can seamlessly enable audio throughout the app
  document.addEventListener("click", enableAudio);
}

/**
 * Enables the audio after a user click
 */
function enableAudio() {
  debug.log("Enable the app audio");

  isPlaying = true;

  if (_player.paused) {
    debug.log("Play audio when user clicks but check if anything has been queued otherwise play the silence loop");
    debug.log(nextClip);
    if (nextClip) {
      queueAudioClip(nextClip.id, nextClip.loop);
    } else {
      queueAudioClip();
    }
  }

  document.removeEventListener("click", enableAudio);
}

/**
 * Plays the appropriate audio clip when the exercise is detected successfully
 *
 * @param category (str) - The exercise category
 * @param level (int) - The exercise level
 */
function playDetectionSuccess(category, level) {
  let clip = "";
  switch (category) {
  case "Agility":
  case "Balance":
  case "Dance":
    clip = "astro-success-a" + level;
    break;
  case "Combat":
  case "Power":
  case "Strength":
    clip = "astro-success-b" + level;
    break;
  }

  debug.log("Play the detection success audio clip: " + clip);
  queueAudioClip(clip);
}

/**
 * Plays the appropriate audio clip when the exercise detection fails
 *
 * @param category (str) - The exercise category
 */
function playDetectionFailure(category) {
  let clip = "";

  switch (category) {
  case "Agility":
  case "Balance":
    clip = "astro-fail-1";
    break;
  case "Dance":
  case "Power":
    clip = "astro-fail-2";
    break;
  case "Combat":
    clip = "astro-fail-3";
    break;
  case "Strength":
    clip = "astro-fail-4";
    break;
  }

  debug.log("Play the detection failed audio clip: " + clip);
  queueAudioClip(clip);
}

/**
 * Plays the appropriate audio clip when the exercise has completed
 *
 * @param category (str) - the exercise category
 */
function playExerciseComplete(category) {
  let clip = "";

  switch (category) {
  case "Agility":
  case "Balance":
    clip = "astro-complete-1";
    break;
  case "Dance":
  case "Power":
    clip = "astro-complete-2";
    break;
  case "Combat":
  case "Strength":
    clip = "astro-complete-3";
    break;
  }

  debug.log("Play the exercise complete audio clip: " + clip);
  queueAudioClip(clip);
}

/**
 * Creates a request for an audio clip to be played
 *
 * @param id (str) - The id of the audio clip to be plaed
 * @param loop (bool) - Whether to loop the audio clip or not
 */
function queueAudioClip(id, loop) {
  // Check if sprite audio is playing
  switch (id) {
  case "button-1":
    nextClip.name = "Button 1";
    nextClip.id = "button-1";
    nextClip.start = 1;
    nextClip.duration = 1;
    break;
  case "button-4":
    nextClip.name = "Button 4";
    nextClip.id = "button-4";
    nextClip.start = 3;
    nextClip.duration = 1;
    break;
  case "button-2":
    nextClip.name = "Button 2";
    nextClip.id = "button-2";
    nextClip.start = 5;
    nextClip.duration = 1;
    break;
  case "button-3":
    nextClip.name = "Button 3";
    nextClip.id = "button-3";
    nextClip.start = 7;
    nextClip.duration = 1;
    break;
  case "astro-intro":
    nextClip.name = "Astro Intro";
    nextClip.id = "astro-intro";
    nextClip.start = 9;
    nextClip.duration = 2;
    break;
  case "astro-success-a1":
    nextClip.name = "Astro Success 1";
    nextClip.id = "astro-success-a1";
    nextClip.start = 12;
    nextClip.duration = 1;
    break;
  case "astro-success-a2":
    nextClip.name = "Astro Success 2";
    nextClip.id = "astro-success-a2";
    nextClip.start = 14;
    nextClip.duration = 2;
    break;
  case "astro-success-a3":
    nextClip.name = "Astro Success 3";
    nextClip.id = "astro-success-a3";
    nextClip.start = 16;
    nextClip.duration = 2;
    break;
  case "astro-success-b1":
    nextClip.name = "Astro Success 4";
    nextClip.id = "astro-success-b1";
    nextClip.start = 19;
    nextClip.duration = 2;
    break;
  case "astro-success-b2":
    nextClip.name = "Astro Success 5";
    nextClip.id = "astro-success-b2";
    nextClip.start = 21;
    nextClip.duration = 1;
    break;
  case "astro-success-b3":
    nextClip.name = "Astro Success 6";
    nextClip.id = "astro-success-b3";
    nextClip.start = 23;
    nextClip.duration = 2;
    break;
  case "astro-fail-1":
    nextClip.name = "Astro Unsuccessful / Shrug 1";
    nextClip.id = "astro-fail-1";
    nextClip.start = 25;
    nextClip.duration = 2;
    break;
  case "astro-fail-2":
    nextClip.name = "Astro Unsuccessful / Shrug 2";
    nextClip.id = "astro-fail-2";
    nextClip.start = 27;
    nextClip.duration = 2;
    break;
  case "astro-fail-3":
    nextClip.name = "Astro Unsuccessful / Shrug 3";
    nextClip.id = "astro-fail-3";
    nextClip.start = 30;
    nextClip.duration = 1;
    break;
  case "astro-fail-4":
    nextClip.name = "Astro Unsuccessful / Shrug 4";
    nextClip.id = "astro-fail-4";
    nextClip.start = 32;
    nextClip.duration = 2;
    break;
  case "astro-complete-1":
    nextClip.name = "Astro Complete Exercise 1";
    nextClip.id = "astro-complete-1";
    nextClip.start = 34;
    nextClip.duration = 2;
    break;
  case "astro-complete-2":
    nextClip.name = "Astro Complete Exercise 2";
    nextClip.id = "astro-complete-2";
    nextClip.start = 36;
    nextClip.duration = 2;
    break;
  case "astro-complete-3":
    nextClip.name = "Astro Complete Exercise 3";
    nextClip.id = "astro-complete-3";
    nextClip.start = 38;
    nextClip.duration = 2;
    break;
  case "success-ui":
    nextClip.name = "Success UI";
    nextClip.id = "success-ui";
    nextClip.start = 40;
    nextClip.duration = 2;
    break;
  default:
    // Loop the silence track by default
    nextClip.name = "Silence";
    nextClip.id = "silence";
    nextClip.start = 0;
    nextClip.duration = 1;
    loop = true;
  }

  nextClip.loop = !!(typeof loop !== "undefined" && loop);

  currentClip = nextClip.name;

  if (!isPlaying) {
    debug.log("Audio is disabled but will retry if this was initiated by a user click");
    return;
  }

  playAudioClip();
}

/**
 * Plays the audio clip that is next in queue
 */
function playAudioClip() {
  if (_player) {
    // Remove audio clip loop listener
    _player.removeEventListener("timeupdate", () => {});

    // Set the clip start position
    _player.currentTime = nextClip.start;

    // Check for loop
    if (nextClip.loop) {
      _player.ontimeupdate = function() {
        loopAudioClip();
      }
    } else {
      _player.ontimeupdate = function() {
        audioClipEnd();
      }
    }

    debug.log("Play audio clip: " + currentClip);
    debug.log(nextClip);
    _player.play();
  }
}

/**
 * Loops the audio clip currently in queue
 */
function loopAudioClip() {
  let buffer = 0.45;

  // Check if current clip is same as previous
  if (currentClip === nextClip.name) {
    if (_player.currentTime > nextClip.start + nextClip.duration - buffer) {
      debug.log("Looping " + nextClip.id);
      _player.currentTime = nextClip.start;
      _player.play();
    }
  }
}

/**
 * Plays the silence loop after the currently queued audio clip has finished
 */
function audioClipEnd() {
  if (_player.currentTime > nextClip.start + nextClip.duration) {
    debug.log('Current play time: ' + _player.currentTime);
    debug.log("End the audio segment when it has reached its duration");
    queueAudioClip(); // Go back to silence loop
  }
}

/**
 * Mutes the audio
 */
function muteAudio() {
  muted = true;
  _player.volume = 0;
}

/**
 * Unmutes the audio
 */
function unmuteAudio() {
  muted = false;
  _player.volume = maxVolume;
}

export {
  initAudio,
  playDetectionSuccess,
  playDetectionFailure,
  playExerciseComplete,
  queueAudioClip,
  muteAudio,
  unmuteAudio
}
