import React, {Component} from "react"
import {EmailPopUp, Footer, Header, Nav} from "./../components"
import Slider from "react-slick/lib"
import gifshot from "gifshot"
import {hideScene} from "./../js/sceneManager"
import {connect} from "react-redux"
import {queueAudioClip} from "../js/audioManager"
import {checkUserActive,onTrainAction} from "../store/actions"

class TrainingComplete extends Component {
  state = {
    isRecognized: false,
    exercises: {},
    activeTab: "",
    isEmail: false,
    emailSend: false,
    category: "",
    gifs: {},
  };

  componentWillUnmount() {
    this.onClearDeviceOrientation()
  }

  componentDidMount() {
    this.onChangeDeviceOrientation()
    const {state} = this.props.location
    let exercises = (state && state.exercises) || []
    let exercisesResults = (state && state.exercisesResults) || []
    let isRecognized = false;
    let firstTab;
    let exercisesShots =
      (state && state.exercisesShots) ||
      require("./../images/Social-share-image.png")
    let newData = {},
      self = this
    let category = state ? state.category : ""

    console.log(state);

    exercises.map(e => {
      e = e.toLowerCase()
      self.renderToGif(exercisesShots[e], e)
      if(!!(typeof exercisesShots[e] !== 'undefined' && exercisesShots[e].length)){
        isRecognized = true;
        if(!firstTab){
          firstTab = e;
        }
      }
      newData[e] = {
        photo: exercisesShots[e],
        result: !!(typeof exercisesShots[e] !== 'undefined' && exercisesShots[e].length)
      }
    })


    this.setState({
      isRecognized: isRecognized,
      exercises: newData,
      activeTab: firstTab,
      category
    })
    hideScene()
    queueAudioClip("success-ui")
  }

  toggleActive = e => {
    this.setState({
      activeTab: e
    })
  };

  renderToGif = (exercisesShots, ImgElement) => {

    // Only generate GIF if there are screenshots captured
    if(typeof exercisesShots !== 'undefined' && exercisesShots.length){

      let self = this
      gifshot.createGIF({
        gifWidth: 1200,
        gifHeight: 800,
        images: exercisesShots,
        interval: 0.3,
        numFrames: 2,
        frameDuration: 1,
        sampleInterval: 10,
        numWorkers: 2
      }, function (obj) {
        if (!obj.error) {
          var image = obj.image
          self.setState((prev) => {
            return {
              gifs: {
                ...prev.gifs,
                [ImgElement]: image
              }
            }
          })
        }
      })
    }
  }


  onChangeDeviceOrientation = async () => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    try {
      if (isMobile) {
        try {
          await window.document.documentElement.requestFullscreen()
        }
        catch(e){
          console.log(e)
        }
        if (window.document.documentElement.webkitRequestFullScreen) {
          window.document.querySelector("#root").webkitRequestFullScreen()
        }

        window.screen.orientation
          .lock("landscape")
          .then(function () {
          })
          .catch(function (error) {
          })
      }
    }
    catch(e) {
      console.log(e)
    }
  };

  onClearDeviceOrientation = () => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    if (isMobile) {
      if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen()
      }
      else if (window.document.exitFullscreen){
        window.document.exitFullscreen()
      }

    }
    window.screen.orientation.unlock()
  };

  next = e => {
    const event = Object.keys(this.state.exercises).indexOf(e)
    this.slider.slickGoTo(event)
  };

  emailHandel = () => {
    this.setState({
      isEmail: !this.state.isEmail,
      emailSend: false
    })
  };

  trainAgain = () => {
    this.props.OnChangeTrainAction(true)
    queueAudioClip("button-1")
  };

  render() {
    var settings = {
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrow: false,
      dots: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ],
      afterChange: current => {
        const event = Object.keys(exercises)[current]
        toggleActive(event)
      }
    }
    const {
      exercises,
      activeTab,
      isEmail,
      emailSend,
      category,
      gifs,
      isRecognized
    } = this.state
    const {
      toggleActive,
      emailHandel,
      next,
      trainAgain,
    } = this
    const {color, shoeType} = this.props

    return (
      <>
        <Header/>
        <Nav props={this.props}/>
        <section className={"complete-training " + `${color}`}>
          <div
            className={
              "complete-train" +
              `${isEmail ? " blur" : ""}` +
              " " +
              `${!isRecognized ? " failure" : ""}` +
              " " +
              `${this.props.isTrainActive ? "fade" : ""}`
            }
          >
            <figure className="share-img flex-col">
              {console.log('THE ACTIVE TAB: ', activeTab)}
              {Object.keys(exercises).map((e, i) => {
                if(exercises[e].result && typeof activeTab !== 'undefined' && e === activeTab.toLowerCase()){
                  return (
                      <img
                          key={i}
                          src={gifs[e.toLowerCase()] || exercises[e].photo[0]}
                          alt="share image"
                      />
                  )
                }
              })}
            </figure>
            <div className="train-session flex-col">
              <h4 className="session-heading">
                {isRecognized ? ("TRAINING SESSION COMPLETE") : ("ASTRO COULD NOT COMPLETE") }
              </h4>
              {(isRecognized) && <p>Please select the exercise you want to share.</p>}
              {Object.keys(exercises).map((e, i) => {
                e = e.toLowerCase()
                let labelClass = (typeof activeTab !== 'undefined' && e === activeTab.toLowerCase()) ? "exercise-active" : "exercise-name";
                labelClass = (!isRecognized) ? ' exercise-active' : labelClass;
                labelClass += (!exercises[e].result && isRecognized) ? ' disabled' : '';
                return (
                    <>
                      {parseInt(i) % 2 === 0 ? (
                          <h2
                              onClick={() => {
                                toggleActive(e)
                                next(e)
                              }}
                              className={ labelClass }
                          >
                            {e}
                            {i === 0 && i !== Object.keys(exercises).length - 1 && (
                                <span> + </span>
                            )}
                          </h2>
                      ) : (
                          <h2
                              onClick={() => {
                                toggleActive(e)
                                next(e)
                              }}
                              className={ labelClass }
                          >
                            {e}{" "}
                            {i !== 0 && i !== Object.keys(exercises).length - 1 && (
                                <span>+</span>
                            )}
                          </h2>
                      )}
                    </>
                )
              })}
              <Slider ref={c => (this.slider = c)} {...settings}>
                {Object.keys(exercises).map((e, i) => {
                  if(exercises[e].result && typeof activeTab !== 'undefined' && e === activeTab.toLowerCase()){
                    return (
                        <img
                            key={i}
                            src={gifs[e.toLowerCase()] || exercises[e].photo[0]}
                            alt="share image"
                        />
                    )
                  }
                })}
              </Slider>

              {(isRecognized) &&
                <button onClick={emailHandel} className="astro-btn">share</button>
              }

              <h5 className="train-title">TRAINING PROGRESS</h5>
              <h3 className="progress-heading">{category}</h3>
              <div className="train-progress">
                <div className="level-bar">
                  <span className="level-points beginner"></span>
                  <span className="level-points medium"></span>
                  <span className="level-points advance"></span>
                  <div className="level-value"></div>
                </div>
              </div>
              <button onClick={trainAgain} className="astro-btn">
                {isRecognized ? ("KEEP TRAINING") : ("TRY AGAIN")}
              </button>
            </div>
          </div>
        </section>
        <Footer color={color}/>
        {isEmail ? (
          <EmailPopUp
            color={color}
            gifs={gifs}
            activeGif={activeTab}
            shoeType={shoeType}
            handelPopUp={emailHandel}
            emailSend={emailSend}
          />
        ) : (
          ""
        )}
      </>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    OnChangeTrainAction: val =>dispatch(onTrainAction(val)),
    onUserActive: () =>dispatch(checkUserActive())
  }
}

const mapStateToProps = state => {
  return {
    isTrainActive: state.isTrainActive,
    isUserActive: state.isUserActive,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainingComplete)
