import React from "react";
import CheckMark from "./../../images/tick.svg";
import LineUp from "./../../images/line-up-astro.png";
import {isWarmedUp} from "../../utils/helper";

export default class Loader extends React.Component {

    /**
     * Returns the content for the current part
     *
     * @returns {*}
     */
    getPart(){
        let output = '';

        // Update the module content according to the set timing
        switch(this.props.currentPart.name){
            case 'intro':
                output = ((!isWarmedUp())?
                        <>
                            <h2 className="text-heading">Let's start <br/> with a<span> warm up</span></h2>
                            <p className="text-para">Make sure you’ve cleared enough space to complete your exercises.</p>
                            <button className="astro-btn" onClick={ this.props.startTimer }>Let's Do It</button>
                        </> : <>
                            <h2 className="step2-heading"><img src={LineUp} alt="Line up with Astro to Begin"/></h2>
                            <br/>
                            <button className="astro-btn" onClick={ this.props.startTimer }>Let's Do It</button>
                        </>
                );
                break;
            case 'get-aligned':
                output = (<h2 className="step2-heading"><img src={LineUp} alt="Line up with Astro to Begin"/></h2>);
                break;
            case 'astro-aligned':
                output = (
                    <div className="step3-check">
                        <img src={CheckMark} alt="Check Mark"/>
                    </div>
                );
                break;
        }

        return output;
    }

    render (){
        return (
            <div className="timer">
                <svg viewBox="0 0 64 64" className="landScape-hide">
                    <circle className="timer-circle" r="49%" cx="50%" cy="50%" style={{strokeDasharray: 197}} />
                </svg>
                <div className="timer-text">
                    {this.getPart()}
                </div>
            </div>
        )
    }
}