import React, { Component } from "react"
import { Link } from "react-router-dom"

export default class MobilePopUp extends Component {
  render() {
    console.log(this.props)
    return (
      <div className="pop-up mobo-pop">
        <div className={"cam-access " + `${this.props.color}`}>
          <p className="access-para">
            For a more expansive experience, visit Astro on a desktop with a
            camera.
          </p>
          <div className="pop-up-btn">
            <Link to={this.props.target} className="astro-btn cancel-btn">
              Continue
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
