import React, { Component } from "react"
import {Home,
  Watch,
  Evolution,
  Training,
  StartTraining,
  TrainingComplete,
  StartTrain,
  Scene,
  Audio} from "./containers"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { onFetchData } from "./store/actions"
import {getShoeType, getBackgroundColor} from "./utils/helper";

class App extends Component {
  state = {
    isActive: true,
    bgColor: getBackgroundColor(),
    shoeType: getShoeType(),
  };

  componentWillMount() {
    this.props.onFetchData()
  }
  addClassHandler = color => {
    this.setState({
      bgColor: color
    })
  };

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Home
                  color={this.state.bgColor}
                  shoeType={this.state.shoeType}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/watch"
              render={props => (
                <Watch
                  color={this.state.bgColor}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/training/:name"
              render={props => (
                <Training
                  color={this.state.bgColor}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/training/:name/start-train"
              render={props => (
                <StartTrain
                  color={this.state.bgColor}
                  shoeType={this.state.shoeType}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/start-training"
              render={props => (
                <StartTraining
                  color={this.state.bgColor}
                  shoeType={this.state.shoeType}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/evolution"
              render={props => (
                <Evolution
                  color={this.state.bgColor}
                  shoeType={this.state.shoeType}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="/training-complete"
              render={props => (
                <TrainingComplete
                  color={this.state.bgColor}
                  shoeType={this.state.shoeType}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
            <Route
              exact
              path="*"
              render={props => (
                <Home
                  color={this.state.bgColor}
                  shoeType={this.state.shoeType}
                  chooseColor={this.addClassHandler}
                  {...props}
                />
              )}
            />
          </Switch>
        </Router>
        <Scene />
        <Audio />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchData: () => dispatch(onFetchData())
  }
}

export default connect(
  null,
  mapDispatchToProps
)(App)
