export default class Debug {
    constructor(enabled, display, handle) {
        this.debugger = null;
        this.enabled = enabled;
        this.display = display;
        this.handle = (typeof handle !== 'undefined' && handle) ? '(' + handle + ') ': '';

        if(typeof enabled !== 'undefined' && enabled){
            console.log('DEBUG MODE ENABLED ' + this.handle);

            if(typeof display !== 'undefined' && display){
                console.log('DISPLAY DEBUG LOG');

                // Setup the debugger element
                var container = document.getElementById('debugger');

                if(!container){
                    this.createDebugger();
                } else {
                    this.debugger = document.getElementById('debugger');
                }
            }
        }
    }

    /**
     * Gets the current time
     * @returns {string}
     */
    getTime(){
        var d = new Date();
        var dateText = d.toTimeString();

        return '[' + dateText.split(' ')[0] + ']';
    }

    /**
     * Creates an element for displaying the log in the DOM
     */
    createDebugger(){
        if(!this.debugger){
            this.debugger = document.createElement('div');
            this.debugger.setAttribute('class', 'debug');
            this.debugger.setAttribute('id', 'debugger');
            document.body.append(this.debugger);

            // Add styling for the debugger element
            var css = document.createElement("style");
            var styles = document.createTextNode("" +
                "#debugger { position: fixed; bottom: 0; left: 0; z-index: 99999999999; display: flex; flex-direction: column; pointer-events: none; width: 100%; padding: 12px; align-items: flex-start; justify-content: flex-end; box-sizing: border-box; }" +
                ".debugger-log { color: white; padding: 2px 6px; text-shadow: -1px 1px 0 rgba(0,0,0,.25); font-size: 12px; margin: 0; white-space: nowrap; }" +
                ".debugger-log.error { color: #F00; }" +
                "");
            css.appendChild(styles);
            document.head.appendChild(css);
        }
    }

    /**
     * Display the log message on the page
     *
     * @param message
     * @param err
     */
    displayLog(message, err) {
        // Check if the debugger element exists
        if (this.debugger) {
            var p = document.createElement('p');
            p.setAttribute('class', 'debugger-log' + ((err) ? ' error' : ''));
            p.append(this.handle + ' ' + this.getTime() + ' ' + message + (typeof message === 'object' ? ' * See console for details' : ''));
            this.debugger.append(p);
        }
    }


    /**
     * Outputs the log in the console and on the page
     *
     * @param message
     * @param err
     */
    log(message, err){
        // Check if the debugger is enabled
        if(this.enabled) {

            // Check if there is a message to output
            if (message) {

                // Check if the log should be displayed on the page
                if(this.display){
                    this.displayLog(message, err);
                }

                // Check if this is an error log
                if (err) {
                    console.error('DEBUG ' + this.handle + this.getTime() + ': ' + message);
                } else {
                    console.log('DEBUG ' + this.handle + this.getTime() + ': ' + message, (typeof message === 'object' ? message : ''));
                }
            }
        }
    }

    /**
     * Outputs an error log
     *
     * @param message
     */
    error(message){
        this.log(message, true);
    }
}