import { TrainImages } from "./const"

export const Categories = (excircises, cateories) => {
  var newCat = {
  }
  for (var i = 0; i <= cateories.length - 1; i++) {
    var find = []
    for (var j = 0; j <= excircises.length - 1; j++) {
      if (cateories[i].exercise_category === excircises[j].exercise_category) {
        find.push(excircises[j])
      }
    }
    if (find.length) {
      newCat[cateories[i].exercise_category] = find
    }
  }
  return newCat
}

export const Exercises = excircises => {
  var newExer = {
  }
  for (var i = 0; i <= excircises.length - 1; i++) {
    newExer[excircises[i].exercise_name] = excircises[i]
  }
  return newExer
}

export const TrainCat = categories => {
  var newCat = categories
  for (var i = 0; i <= categories.length - 1; i++) {
    var find = false
    for (var j = 0; j <= TrainImages.length - 1; j++) {
      if (
        categories[i].exercise_category.toUpperCase() ===
        TrainImages[j].trainImage.split(".")[0]
      ) {
        find = true
        newCat[i].trainImage = TrainImages[j].trainImage
        newCat[i].trainImage2 = TrainImages[j].trainImage2
      }
    }
    if (!find) {
      newCat[i].trainImage = TrainImages[0].trainImage
      newCat[i].trainImage2 = TrainImages[0].trainImage2
    }
  }

  var mapData = []
  newCat.map(cat => {
    if (cat.exercise_category !== "") {
      mapData.push(cat)
    }
  })
  return mapData
}


export const CalculateCategoryLevel=(subCategory)=>{
  var totalScore=0
  subCategory.map(item=>{
    totalScore+=(item.score * 100) / 10000
  })
  var result=(totalScore/(subCategory.length*100))*100
  console.log("scroll",result)

  return result
}

/**
 * Gets the shoeType value from local storage
 * @returns {string}
 */
export function getShoeType () {
  let val = window.localStorage.getItem('shoeType');
  return (val) ? val : 'male'
}

/**
 * Sets the shoeType value in local storage
 * @param val
 */
export function setShoeType (val) {
  if(typeof val !== 'undefined'){
    window.localStorage.setItem('shoeType', val);
  }
}

/**
 * Gets the backgroundColor value from local storage
 * @returns {string}
 */
export function getBackgroundColor() {
  let val = window.localStorage.getItem('backgroundColor');
  return (val) ? val : 'home-red'
}

/**
 * Sets the backgroundColor value in local storage
 * @param val
 */
export function setBackgroundColor(val) {
  if(typeof val !== 'undefined'){
    window.localStorage.setItem('backgroundColor', val);
  }
}

/**
 *
 */
export function toggleOptions(){
  var body = document.body;

  // Remove all option active classes
  body.classList.remove('select-background-active', 'select-shoe-active');

  if(JSON.parse(window.localStorage.getItem('selectBackground'))){
    body.classList.add('select-background-active');
    console.log('Activate Background');
  }

  if(JSON.parse(window.localStorage.getItem('selectShoe'))){
    body.classList.add('select-shoe-active');
    console.log('Activate Shoe');
  }
}


/**
 * Gets the cameraPermission session cookie value
 * @returns {string|boolean}
 */
export function getCameraPermission(){
  let name = "cameraPermission=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return false;
}

/**
 * Sets the cameraPermission session cookie value
 */
export function setCameraPermission(){
  document.cookie = 'cameraPermission=1';
}

/**
 * Gets the isWarmedUp session cookie value
 * @returns {string|boolean}
 */
export function isWarmedUp() {
  let name = "isWarmedUp=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return false;
}

/**
 * Sets the isWarmedUp session cookie cookie
 */
export function setWarmUp() {
  document.cookie = 'isWarmedUp=1';
}