import React from "react"

export const WatchVideo = props => {
  const { url, title, info } = props
  return (
    <>
      <div className="watch-video">
        <div className="video-wrapper">
          <iframe
            src={url}
            width="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
        <h2 className="athlete-name">{title}</h2>
        <p className="athlete-info">{info}</p>
      </div>
    </>
  )
}
