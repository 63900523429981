import React, { Component } from "react"
import {EvolutionLevel,
  Footer,
  Header,
  Nav,
  SelectBackground,
  SelectShoe} from "./../components"
import { connect } from "react-redux"
import {placeAstro, playEvolution, resizeScene} from "../js/sceneManager"

class Evolution extends Component {
  state = {
    isActive: "",
    isSelectedCategory: "",
    mobileSublevel: false,
  };

  toggleMobileLevel = category => {

    // let expanded = !(this.state.mobileSublevel);
    //
    // console.log(expanded);
    //
    this.setState({
      isSelectedCategory: category,
      mobileSublevel: true,
    });
  };

  handelSublevel = event => {
    this.setState({
      isActive: (this.state.isActive !== event) ? event : '',
      mobileSublevel: false,
    });
  };

  componentDidMount() {
    console.log(this.props.shoeType);
    playEvolution()

    window.addEventListener("resize", this.onResizeWindow)
  }

  onResizeWindow = () => {
    if(window.innerWidth >= 768){
      this.setState({
        mobileSublevel: false
      })
    }
  };

  componentDidUpdate() {
    placeAstro(true);
  }

  render() {
    const { handelSublevel, toggleMobileLevel } = this
    const { evolutions, isSelectedCategory, isActive, mobileSublevel } = this.state
    const { color, shoeType, chooseColor, categoryType, categories } = this.props
    return (
      <>
        <Header />
        <SelectBackground color={color} chooseColor={clr => chooseColor(clr)} />
        <SelectShoe color={color} shoeType={shoeType} chooseColor={clr => {}} />
        <Nav props={this.props} />
        <section className={"evolution " + `${color}`}>
          <div
            className={
              "evolution-levels " + `${isSelectedCategory ? "mobile-expanded " : ""}` + `${this.props.isTrainActive ? " fade" : ""}`
            }
          >
            <div className="levels">
              {categoryType.map((item, index) => {
                if (item.exercise_category === "") return
                return (
                  <div key={index}>
                    {isActive === "" ? (
                      <EvolutionLevel
                        categories={categories}
                        handelSublevel={handelSublevel}
                        toggleMobileLevel={toggleMobileLevel}
                        mobileSublevel={mobileSublevel}
                        name={item.exercise_category}
                        subLevelShow={isActive !== item.exercise_category}
                        LevelValue={8}
                        background={color}
                      />
                    ) : isActive === item.exercise_category ? (
                      <EvolutionLevel
                        categories={categories}
                        handelSublevel={handelSublevel}
                        toggleMobileLevel={toggleMobileLevel}
                        mobileSublevel={mobileSublevel}
                        name={item.exercise_category}
                        subLevelShow={isActive !== item.exercise_category}
                        LevelValue={8}
                        background={color}
                      />
                    ) : null}
                  </div>
                )
              })}
              <div className="mob-levels">
                <div
                  className={"levels-names " + `${!isActive ? "" : " hide"}`}
                >
                  {this.props.categoryType.map((category, key) => {
                    return (
                      <h3
                        key={key}
                        onClick={() => toggleMobileLevel(category.exercise_category)}
                        className={
                          "level-name " +
                          `${
                            isSelectedCategory === category.exercise_category
                              ? "active"
                              : ""
                          }`
                        }
                      >
                        {category.exercise_category}
                      </h3>
                    )
                  })}
                </div>
                {categoryType.map((item, index) => {
                  if (item.exercise_category === "") return
                  return (
                    <div key={index}>
                      {item.exercise_category === isSelectedCategory ? (
                        <EvolutionLevel
                          categories={categories}
                          handelSublevel={handelSublevel}
                          mobileSublevel={mobileSublevel}
                          toggleMobileLevel={toggleMobileLevel}
                          show={isActive !== item.exercise_category}
                          name={item.exercise_category}
                          value={8}
                        />
                      ) : null}
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="levels-ai"></div>
          </div>
        </section>
        <Footer color={color} />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categories,
    exercises: state.exercises,
    categoryType: state.categoryType,
    isTrainActive: state.isTrainActive
  }
}

export default connect(mapStateToProps)(Evolution)
