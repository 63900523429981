import React from "react";
import {queueAnimation} from "../../js/sceneManager";
import FollowAstro from "./../../images/follow-astro.png";
import WarmUpComplete from "./../../images/warm-up-complete.png";
import {setWarmUp} from "../../utils/helper";

export default class WarmUp extends React.Component {
    state = {
        isWarmingUp: false,
    };

    componentDidUpdate() {
        // Ensure that the warm up animation only triggers once
        if(this.props.currentPart.name === 'follow-astro-countdown' && !this.state.isWarmingUp){
            queueAnimation("WarmUp", true);
            queueAnimation("MainIdle");

            // Add the transition class to the loading circle after a small delay so that it doesn't reverse
            setTimeout(() => {
                this.circle.classList.add('loader-transition');
            }, 100);

            this.setState({isWarmingUp: true});
        }

        // Reset the attributes after the warm up has finished set the cookie
        if(this.props.currentPart.name === 'warm-up-complete' && this.state.isWarmingUp){
            this.circle.classList.remove('loader-transition');
            this.setState({isWarmingUp: false});
            setWarmUp();
        }
    }

    /**
     * Determines if the circle loader should animate
     *
     * @param max (integer) - The max value to calculate the loader percentage against
     * @returns {*}
     */
    animateLoader(max){
        let output = max;

        // Check if current part should use the animated loader loader circle
        if(this.props.currentPart.name === 'follow-astro-countdown'){
            output = this.props.getLoaderValue(max);
        }

        return output;
    }

    /**
     * Returns the content for the current part
     *
     * @returns {*}
     */
    getPart(){
        let output = '';

        // Update the module content according to the set timing
        switch(this.props.currentPart.name){
            case 'follow-astro':
            case 'follow-astro-countdown':
            case 'follow-astro-countdown-complete':
                output = (<h2 className="step2-heading"><img src={FollowAstro} alt="Follow Astro's Warm Up"/></h2>);
                break;
            case 'warm-up-complete':
                output = (<h2 className="step2-heading"><img src={WarmUpComplete} alt="Warm Up Complete"/></h2>);
                break;
        }

        return output;
    }

    render (){
        return (
            <div className="timer">
                <svg viewBox="0 0 64 64" className="landScape-hide">
                    <circle className="timer-circle" r="49%" cx="50%" cy="50%" style={{strokeDasharray: this.animateLoader(198) + ' 197'}} ref={ref => (this.circle = ref)} />
                </svg>
                <div className="timer-text">
                    {this.getPart()}
                </div>
            </div>
        )
    }
}