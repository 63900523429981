import React from "react"
import ReactDOM from "react-dom"
import "./styles/css/font-awesome.min.css"
import "./styles/css/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import ReduxThunk from "redux-thunk"
import Reducer from "./store/reducer"

const crateStoreWithMiddleWare = applyMiddleware(ReduxThunk)(createStore)

const store = crateStoreWithMiddleWare(Reducer)
const app = (
  <Provider store={store}>
    <App />
  </Provider>
)
ReactDOM.render(app, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
