import React, { Component } from "react"

export default class VideoCam extends Component {

  componentDidMount() {
      this.startCameraFeed();
  }

  startCameraFeed() {
      let self = this;
      navigator.mediaDevices
          .getUserMedia({
              video: true
          })
          .then(function(stream) {
              self.video.srcObject = stream;
              self.video.className = "grayscale";
              self.video.play();
          })
  }

  render() {
    return (
      <div className="video-container">
        <video id="video" playsInline width="640" height="480" ref={ref => (this.video = ref) }/>
        <canvas id="canvas" width="640" height="480" hidden/>
        <br />
        <div id="category" />
        <div id="exercises" />
        <div id="orientation" />
        <div id="buttons" />
      </div>
    )
  }
}
