import * as BABYLON from "babylonjs"
import React, { Component } from "react"
import { resizeScene } from "../js/sceneManager"

export type SceneEventArgs = {
  engine: BABYLON.Engine,
  scene: BABYLON.Scene,
  canvas: HTMLCanvasElement,
};

export type SceneProps = {
  engineOptions?: {
    preserveDrawingBuffer: true,
    stencil: true
  },
  adaptToDeviceRatio?: boolean,
  onSceneMount?: (args: SceneEventArgs) => void,
  width?: number,
  height?: number,
};

export default class SceneCanvas extends Component<
  SceneProps & React.HTMLAttributes<HTMLCanvasElement>,
  {}
> {
  onResizeWindow = () => {
    resizeScene()
    this.forceUpdate()
  };

  componentDidMount() {
    this.engine = new BABYLON.Engine(
      this.canvas,
      true,
      this.props.engineOptions,
      this.props.adaptToDeviceRatio
    )

    if (typeof this.props.onSceneMount === "function") {
      this.props.onSceneMount({
        engine: this.engine,
        canvas: this.canvas
      })
    } else {
      console.error("onSceneMount function not available")
    }

    // Resize the babylon engine when the window is resized
    window.addEventListener("resize", this.onResizeWindow)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeWindow)
  }

  onCanvasLoaded = (c: HTMLCanvasElement) => {
    if (c !== null) {
      this.canvas = c
    }
  };

  render() {
    // 'rest' can contain additional properties that you can flow through to canvas:
    // (id, className, etc.)
    const { width, height, ...rest } = this.props

    const opts: any = {
    }

    opts.id = "scene"

    if (width !== undefined && height !== undefined) {
      opts.width = width
      opts.height = height
    } else {
      opts.width = window.innerWidth
      opts.height = window.innerHeight
    }

    return <canvas {...opts} ref={this.onCanvasLoaded} />
  }
}
