export const API = "https://pumaweb.currentstudios.io:8080"

export const TrainImages = [
  {
    trainImage2: "STRENGTH_COLOUR.png",
    trainImage: "STRENGTH.png"
  },
  {
    trainImage2: "POWER_COLOUR.png",
    trainImage: "POWER.png"
  },
  {
    trainImage2: "AGILITY_COLOUR.png",
    trainImage: "AGILITY.png"
  },
  {
    trainImage2: "BALANCE_COLOUR.png",
    trainImage: "BALANCE.png"
  },
  {
    trainImage2: "DANCE_COLOUR.png",
    trainImage: "DANCE.png"
  },
  {
    trainImage2: "COMBAT_COLOUR.png",
    trainImage: "COMBAT.png"
  }
]



export const BUCKET_NAME = "puma-astro-web";
export const POOL_ID = "ap-southeast-2:e5616654-2cc7-4657-9ab2-c3db31c7dd89"
export const REGION = "ap-southeast-2";

export const MALE_SHOE_URL = "https://a.klaviyo.com/api/v1/email-template/MyM8VH/send"
export const FEMALE_SHOE_URL = "https://a.klaviyo.com/api/v1/email-template/M9EmyJ/send"
