import React from "react"
import { Link } from "react-router-dom"
import { ON_CHANGE_TRAIN_ACTION } from "../store/types"
import { connect } from "react-redux"
import { playIntro, showScene } from "./../js/sceneManager"

const Header = props => {
  const onClickLinks = () => {
    props.OnChangeTrainAction(false)

    // Play the intro whenever the user browses to the home page from someplace else in the app
    if (window.location.pathname.length > 1) {
      playIntro()
    } else {
      showScene()
    }
  }
  return (
    <>
      <header className="header">
        <Link onClick={onClickLinks} className="astro-logo" to="/">
          <img
            src={require("./../images/astro-ByPuma.png")}
            alt="Astro By Puma"
          />
        </Link>
          <a href="https://au.puma.com/">
              <figure className="puma-logo">
                  <img src={require("./../images/puma-logo.png")} alt="Puma logo" />
              </figure>
          </a>
      </header>

      {/*<header className='header'>*/}
      {/*<Link onClick={onClickLinks} className='astro-logo' to="/">*/}
      {/*Astro <span className='puma'>By puma</span>*/}
      {/*<img src={require("./../images/puma-logo2.png")} alt="puma logo2"/>*/}
      {/*</Link>*/}
      {/*<Link onClick={onClickLinks} className='puma-logo' to="/">*/}
      {/*<img src={require("./../images/puma-logo.png")} alt='puma-logo'/>*/}
      {/*</Link>*/}
      {/*</header>*/}
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    OnChangeTrainAction: val =>
      dispatch({
        type: ON_CHANGE_TRAIN_ACTION,
        isChange: val
      })
  }
}

export default connect(
  null,
  mapDispatchToProps
)(Header)
