import { InitPosenetData, InitWarmUpData } from "../js/posenetUtils.js"
import { allowedTags } from "../js/detection.js"
var _ = require("lodash")

var poseData = []
var poseTags = []
var warmUpData = []
var warmUpTags = []
var warmUpExercises = ["Jog On Spot", "Side Bend", "Arm Rotation"]
var referenceImages
var json = require("./PosenetData/posenetData")
async function getData() {
  console.log("GET DATA")
  poseTags = []
  poseData = []
  let resp = await read()
  if (resp) {
    poseTags = resp
    InitPosenetData()
  }
}

async function read() {
  return new Promise((resolve, reject) => {
    var data = _.pick(json, allowedTags)
    for (var i in data) {
      poseData = poseData.concat(Array.from(data[i]))
      for (var j in data[i]) {
        // console.log(j);
        poseTags.push(i)
      }
    }
    resolve(poseTags)
  })
}

async function LoadPosenetData() {
  console.log("Load Posenet")
  fetch(
    "https://storage.googleapis.com/pumaastro-posenet-data/posenetData.json"
  ).then(function(resp) {
    resp.json().then(function(data) {
      json = data
      warmUpData = []
      warmUpTags = []
      SetUpWarmUpData()
    })
  })
}

async function SetUpWarmUpData() {
  return new Promise(function(resolve, reject) {
    var data = _.pick(json, warmUpExercises)
    for (var i in data) {
      warmUpData = warmUpData.concat(Array.from(data[i]))
      for (var j in data[i]) {
        warmUpTags.push(i)
      }
    }
    InitWarmUpData()
    resolve()
  })
}

LoadPosenetData()

export {
  poseData,
  poseTags,
  referenceImages,
  getData,
  warmUpData,
  warmUpTags,
  warmUpExercises
}
